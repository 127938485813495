import { useState, useEffect, useRef } from 'react';
import { Col, TextInput } from 'react-materialize';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({ thumbnailState, action, imageProp }) => {
    const [thumbnailImage, setThumbnailImage] = useState();
    const [thumbnailPreviewUrl, setThumbnailPreviewUrl] =
        useState(thumbnailState);

    const onMount = useRef(true);

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            action(thumbnailImage, imageProp);
        }
    }, [thumbnailImage, action, imageProp]);

    const handleOnChangeFile = (e) => {
        const fileSize = e && e.target.files[0].size < 5000000; // 2mb
        if (fileSize) {
            setThumbnailImage(e.target.files[0]);
            setThumbnailPreviewUrl(URL.createObjectURL(e.target.files[0]));
        } else {
            alert('File chosen is larger than 5mb');
        }
    };

    const thumbnailPreview = !thumbnailPreviewUrl
        ? thumbnailImage
        : thumbnailPreviewUrl;

    return (
        <Col s={6} m={6} l={6} xl={6}>
            <TextInput
                id={imageProp}
                className="waves-effect waves-light  validate"
                name={imageProp}
                type="file"
                accept="image/png, image/jpeg"
                label={
                    thumbnailPreview
                        ? 'Pick a new thumbnail'
                        : 'Choose thumbnail'
                }
                onChange={handleOnChangeFile}
                s={12}
                m={6}
                l={6}
                xl={6}
            >
                <span className="file-size-helper flex-center col s12 m12 l12 xl12">
                    Max file size 5mb
                </span>
            </TextInput>
            <Col className="thumbnail-wrapper" s={12} m={6} l={6} xl={6}>
                {thumbnailPreview ? (
                    <div className="thumbnail-preview-wrapper">
                        <img
                            className="thumbnail-preview"
                            src={thumbnailPreview}
                            alt="thumbail"
                        />
                    </div>
                ) : (
                    <p className="no-thumbnail">No thumbnail selected</p>
                )}
            </Col>
        </Col>
    );
};

export default ImageUploader;

export const NewImageUploader = (props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
            </aside>
        </section>
    );
};
