import { Link } from 'react-router-dom';

const RecipesList = (props) => {
    const { recipesList, viewerIsAuthor } = props;
    const list = recipesList ? (
        <ul className="row">
            {Object.keys(recipesList).map((recipe) => {
                const recipeInfo = recipesList[recipe];
                const privateRecipe =
                    recipeInfo.visibility === 'private' ? 'private-recipe' : '';
                const backgroundImage = {
                    backgroundImage: 'url(' + recipeInfo.thumbnailImage + ')',
                };
                return (
                    <li className="recipe-item col s12 m6 l4 xl3" key={recipe}>
                        <Link
                            className={'recipe-link ' + privateRecipe}
                            to={'/recipe/' + recipe}
                            style={backgroundImage}
                        >
                            <span className="recipe-link-title">
                                {recipeInfo.title}
                            </span>
                        </Link>
                        {viewerIsAuthor ? (
                            <Link
                                className="author-edit-recipe-link"
                                to={'/editrecipe/' + recipe}
                                title={'Edit ' + recipeInfo.title}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-edit"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#dadada"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                    <line x1="16" y1="5" x2="19" y2="8" />
                                </svg>
                            </Link>
                        ) : null}
                    </li>
                );
            })}
        </ul>
    ) : (
        <p>You currently have no recipes</p>
    );

    return <div className="recipes-list">{list}</div>;
};

export default RecipesList;
