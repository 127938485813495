import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyBWseOUZhjIYaycdJNdac5xUADr2_8p4sc',
    authDomain: 'mymothersrecipes-e0eea.firebaseapp.com',
    databaseURL: 'https://mymothersrecipes-e0eea.firebaseio.com',
    projectId: 'mymothersrecipes-e0eea',
    storageBucket: 'mymothersrecipes-e0eea.appspot.com',
    messagingSenderId: '202734382130',
    appId: '1:202734382130:web:d2b0e01b311697a887a02b',
    measurementId: 'G-8CPQ5EGV80',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
