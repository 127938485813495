import { useState } from 'react';

const LoadingAnimation = (props) => {
    const [loader, setLoader] = useState(true);
    const loadingText = props.loadingText
        ? props.loadingText
        : { loadingState: '', status: 'loading', text: 'loading...' };

    if (loader && loadingText.loadingState === 'animate-out') {
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }

    if (loader) {
        return (
            <div className={'loading-wrapper ' + loadingText.loadingState}>
                <div className={'loading-box ' + loadingText.status}>
                    <svg
                        className="loading-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500.07 444.06"
                    >
                        <title>Pie loading image</title>

                        <g className="pie">
                            <path
                                fill="#ffd67c"
                                d="M54.2,244.58C83.87,193.1,160.28,150,249.86,150s166,43.12,195.64,94.56h-.09A48,48,0,1,1,394,292.41a48,48,0,1,1-96,0,48,48,0,1,1-96,0,48,48,0,0,1-96,0,48,48,0,1,1-51.88-47.83Z"
                            />
                            <line
                                fill="none"
                                strokeLinejoin="round"
                                strokeWidth="10px"
                                stroke="#3e3e3e"
                                strokeLinecap="round"
                                x1="250.03"
                                y1="192.79"
                                x2="250.03"
                                y2="214.57"
                            />
                            <line
                                fill="none"
                                strokeLinejoin="round"
                                strokeWidth="10px"
                                stroke="#3e3e3e"
                                strokeLinecap="round"
                                x1="191.61"
                                y1="192.79"
                                x2="165.64"
                                y2="214.57"
                            />
                            <line
                                fill="none"
                                strokeLinejoin="round"
                                strokeWidth="10px"
                                stroke="#3e3e3e"
                                strokeLinecap="round"
                                x1="308.46"
                                y1="192.79"
                                x2="334.43"
                                y2="214.57"
                            />
                            <path
                                opacity="0.2"
                                d="M442,340.4a48,48,0,0,0,3.42-95.85h.09C415.8,193.08,339.42,150,249.86,150c0,0,143.44,36.49,144.13,142.45A48,48,0,0,0,442,340.4Z"
                            />
                            <path
                                fill="#ff9fa5"
                                strokeLinejoin="round"
                                strokeWidth="10px"
                                stroke="#f3f3f3"
                                strokeLinecap="round"
                                d="M442,340.4a48,48,0,0,1-48-48,48,48,0,0,1-96,0,48,48,0,0,1-96,0,48,48,0,0,1-96,0,47.93,47.93,0,0,1-47.85,48,48.82,48.82,0,0,1-8.36-.72l15.77,59.16a47.41,47.41,0,0,0,45.8,35.2h276a47.42,47.42,0,0,0,45.81-35.2l15.71-59A46.61,46.61,0,0,1,442,340.4Z"
                            />
                            <path
                                opacity="0.3"
                                d="M442,340.4a48,48,0,0,1-47.8-43.73c.53,22.33-3.59,125.64-123.7,137.33h117a47.4,47.4,0,0,0,45.79-35.2l15.71-59A48.27,48.27,0,0,1,442,340.4Z"
                            />
                            <path
                                fill="none"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                d="M54.2,244.58C83.87,193.1,160.28,150,249.86,150s166,43.12,195.64,94.56h-.09A48,48,0,1,1,394,292.41a48,48,0,1,1-96,0,48,48,0,1,1-96,0,48,48,0,0,1-96,0,48,48,0,1,1-51.88-47.83Z"
                            />
                            <path
                                className="steam"
                                strokeMiterlimit="10"
                                strokeWidth="12px"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                d="M264,9c0,17.2-28,17.2-28,34.41s28,17.2,28,34.41S236,95,236,112.23"
                            />
                            <path
                                className="steam"
                                strokeMiterlimit="10"
                                strokeWidth="12px"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                d="M383.75,51.56c0,13.84-28,13.84-28,27.68s28,13.85,28,27.69-28,13.84-28,27.68"
                            />
                            <path
                                className="steam"
                                strokeMiterlimit="10"
                                strokeWidth="12px"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                d="M144,54.56c0,13.84-28,13.84-28,27.68s28,13.85,28,27.69-28,13.84-28,27.68"
                            />
                        </g>
                        <g className="success">
                            <line
                                className="tick tick-part-1"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="50.07px"
                                x1="132.13"
                                y1="227"
                                x2="237.13"
                                y2="363.35"
                            />
                            <line
                                className="tick tick-part-2"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="50.07px"
                                x1="392.13"
                                y1="93.15"
                                x2="236.13"
                                y2="365.35"
                            />
                        </g>
                    </svg>
                    <p className="loading-text">{loadingText.text}</p>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default LoadingAnimation;
