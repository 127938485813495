import { Component } from 'react';
import { connect } from 'react-redux';
import { recoverPassword } from '../../store/actions/authActions';
import 'materialize-css';
import { TextInput, Row, Col } from 'react-materialize';
import LoadingAnimation from '../modules/loadingAnimation';

class RecoverPassword extends Component {
    state = {
        email: '',
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    handleOnSubmit = (e) => {
        e.preventDefault();
        this.props.recoverPassword(this.state.email);
    };

    render() {
        const { recoverError, recoverSuccess } = this.props;

        const taskStatus = recoverSuccess
            ? {
                  loadingState: 'animate-out',
                  status: 'task-complete',
                  text: 'Email sent!',
              }
            : null;

        return (
            <div className="page-wrapper login-page">
                <form className="login-form" onSubmit={this.handleOnSubmit}>
                    <div className="container">
                        <Row>
                            <Col s={12} m={12} l={12} xl={12}>
                                <h1 className="page-heading">
                                    Recover Password
                                </h1>
                            </Col>
                        </Row>

                        <Row>
                            <TextInput
                                type="email"
                                id="email"
                                label="Email"
                                name="email"
                                onChange={this.handleInputChange}
                                s={12}
                                m={6}
                                l={6}
                                xl={6}
                            />
                        </Row>

                        <Row>
                            <Col s={12} m={6} l={6} xl={6}>
                                <button
                                    className="btn waves-effect waves-light"
                                    type="submit"
                                >
                                    Send recover password email
                                </button>
                            </Col>
                        </Row>

                        <div className="login-error">
                            {recoverError && (
                                <Row>
                                    <Col s={12} m={12} l={12} xl={12}>
                                        <p>{recoverError}</p>
                                    </Col>
                                </Row>
                            )}
                            {recoverSuccess && (
                                <Row>
                                    <Col s={12} m={12} l={12} xl={12}>
                                        <p>
                                            Recover password email has been
                                            sent. Check your email and click the
                                            link to reset your password
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </div>
                </form>
                {recoverSuccess && (
                    <LoadingAnimation loadingText={taskStatus} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recoverError: state.auth.recoverError,
        recoverSuccess: state.auth.recoverSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        recoverPassword: (emailaddress) =>
            dispatch(recoverPassword(emailaddress)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
