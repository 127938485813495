export const approveNotification = (id, state, collection) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        const loggedInUser = getState().firebase.auth.uid;

        firestore
            .collection(collection)
            .doc(id)
            .update({
                ...state,
                approved: loggedInUser,
                timeApproved: new Date(),
            })
            .then(() => {
                dispatch({
                    type: 'APPROVE_CHANGE',
                    id,
                });
            })
            .catch((err) => {
                // dispatch({
                //     type: 'UPDATE_RECIPE_ERROR',
                //     err,
                // });
                console.log('no fanks', err);
            });
    };
};
