import { Chip, Icon } from 'react-materialize';

const ShowTags = (props) => {
    const { tags } = props;

    const showAllTags = tags.map((tag, i) => {
        return (
            <Chip
                close={false}
                closeIcon={<Icon className="close">close</Icon>}
                options={null}
                key={i}
            >
                {tag}
            </Chip>
        );
    });
    return showAllTags;
};

export default ShowTags;
