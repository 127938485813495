import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Icon } from 'react-materialize';
import { CookingTimersContext } from '../../../store/contexts/cookingTimersContext';

const CountdownTimer = (props) => {
    const { duration, startPaused, stepIndex, originalTimerInSeconds } = props;
    // initialize timeLeft with the seconds prop
    const [timeLeft, setTimeLeft] = useState(duration);
    const [pause, setPause] = useState(startPaused);
    const { dispatch } = useContext(CookingTimersContext);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft || pause) {
            return;
        }

        // save intervalId to clear the interval when the
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft, pause]);

    const hourPart =
        moment.utc(timeLeft * 1000).format('H') !== '0'
            ? moment.utc(timeLeft * 1000).format('H') + 'hr:'
            : '';

    const minutePart =
        moment.utc(timeLeft * 1000).format('mm') !== '00'
            ? moment.utc(timeLeft * 1000).format('mm') + ':'
            : '';

    const secondsPart = moment.utc(timeLeft * 1000).format('ss');

    const showSeconds = !hourPart && !minutePart ? 'secs' : '';

    const humanReadableTimer =
        hourPart + minutePart + secondsPart + showSeconds;

    const handleStartPause = (e) => {
        e.preventDefault();

        let timer = {
            startTime: moment().unix(),
            timeRemaining: timeLeft,
        };
        switch (pause) {
            case true: // Pause timer
                timer.paused = false;
                timer.startTime = moment().unix();

                break;
            case false: // Start/Resume timer
                timer.paused = true;

                break;
            default:
        }
        dispatch({ type: 'SET_TIMER', value: timer, index: stepIndex });
        setPause((pause) => !pause);
    };

    const handleReset = (e) => {
        e.preventDefault();
        setTimeLeft(originalTimerInSeconds);
        const newTimer = {
            startTime: moment().unix(),
            timeRemaining: originalTimerInSeconds,
            paused: false,
        };
        dispatch({ type: 'SET_TIMER', value: newTimer, index: stepIndex });
        setPause((pause) => !pause);
    };

    // BONUS MAKE CLOCK SHAKE WHEN FINISHED LOLS
    const almostDone =
        !pause && timeLeft <= 10 && timeLeft !== 0 ? ' almost-done' : '';

    return (
        <div className="cm-step-timer temp-show">
            {(originalTimerInSeconds !== duration && pause) || !timeLeft ? (
                // {pause ? (
                <button className="reset-time" onClick={handleReset}>
                    <Icon>restore</Icon>
                    <span className="sr-only">reset timer</span>
                </button>
            ) : (
                ''
            )}
            {timeLeft ? (
                <button className="pause-time" onClick={handleStartPause}>
                    {pause ? <Icon>play_arrow</Icon> : <Icon>pause</Icon>}
                    <span className="sr-only">{pause ? 'start' : 'pause'}</span>
                </button>
            ) : (
                ''
            )}
            <span className={'time-left' + almostDone}>
                {timeLeft ? (
                    <>
                        <Icon>access_alarm</Icon> {humanReadableTimer}
                    </>
                ) : (
                    'all done!'
                )}
            </span>
        </div>
    );
};

export default CountdownTimer;
