import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Row, Col, Tab, Tabs } from 'react-materialize';
import BookMarkList from '../modules/bookmarks/bookMarkList';
import AddNewListModal from '../modules/bookmarks/addNewListModal';
import { setBookMarks } from '../../store/actions/userActions';
import BookMarkContextProvider from '../../store/contexts/bookMarkContext';

const Library = (props) => {
    const {
        auth,
        bookMarks,
        recipes,
        profile,
        setBookMarks,
        recipesLoaded,
        bookMarksLoaded,
    } = props;

    // might need to review this page, limit the recipes list fetch if site gets larger
    if (auth.uid && profile && bookMarksLoaded && recipesLoaded) {
        return (
            <BookMarkContextProvider bookMarks={bookMarks}>
                <div className="library-page page-wrapper">
                    <Row className="library-header">
                        <Col l={12} m={12} s={12} xl={12}>
                            <h1 className="page-heading">Library</h1>
                            {bookMarks &&
                            bookMarks.starred.length &&
                            recipes ? (
                                <AddNewListModal recipes={recipes} />
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col l={12} m={12} s={12} xl={12}>
                            <Tabs className="library-tab browser-default tab-demo z-depth-1">
                                <Tab
                                    active
                                    options={{
                                        duration: 300,
                                        onShow: null,
                                        responsiveThreshold: Infinity,
                                        swipeable: false,
                                    }}
                                    title="Starred Recipes"
                                >
                                    {bookMarks &&
                                    bookMarks.starred.length &&
                                    recipes ? (
                                        <Row>
                                            <BookMarkList
                                                bookMarks={bookMarks}
                                                recipes={recipes}
                                                updateBookMarks={setBookMarks}
                                            />
                                        </Row>
                                    ) : (
                                        <p className="delayed-fadein">
                                            You havent starred anything yet.
                                            Maybe we should dial
                                            1-800-Mary-Berry?
                                        </p>
                                    )}
                                </Tab>
                                {/* <Tab
                                options={{
                                    duration: 300,
                                    onShow: null,
                                    responsiveThreshold: Infinity,
                                    swipeable: false,
                                }}
                                title="Collections"
                            >
                                <Col l={12} m={12} s={12} xl={12}>
                                    <p>Coming soon...</p>
                                </Col>
                            </Tab> */}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </BookMarkContextProvider>
        );
    } else {
        return (
            <div className="library-page page-wrapper">
                <Row className="library-header">
                    <Col l={12} m={12} s={12} xl={12}>
                        <h1 className="page-heading">Library</h1>
                    </Col>

                    <Col l={12} m={12} s={12} xl={12}>
                        <div className="library-public-msg">
                            <p>
                                Really sorry but you need an account to use
                                these neat features.
                            </p>
                            <p>
                                For now, heres a firetruck icon{' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-firetruck"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx="5" cy="17" r="2" />
                                    <circle cx="17" cy="17" r="2" />
                                    <path d="M7 18h8m4 0h2v-6a5 5 0 0 0 -5 -5h-1l1.5 5h4.5" />
                                    <path d="M12 18v-11h3" />
                                    <polyline points="3 17 3 12 12 12" />
                                    <line x1="3" y1="9" x2="21" y2="3" />
                                    <line x1="6" y1="12" x2="6" y2="8" />
                                </svg>{' '}
                                because I think its awesome and it probably
                                never gets used.
                            </p>
                            <p>
                                <Link
                                    className="link-to-register"
                                    to="/register"
                                    title="Register"
                                >
                                    Sign up
                                </Link>{' '}
                                or if you already have an account,{' '}
                                <Link
                                    className="link-to-login"
                                    to="/login"
                                    title="Log in"
                                >
                                    log in here
                                </Link>
                                .
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    const auth = state.firebase.auth;
    const profile = !state.firebase.profile.isEmpty && state.firebase.profile;
    const recipes = state.firestore.data.recipes;
    const bookMarks =
        auth && state.firestore.data.bookMarks
            ? state.firestore.data.bookMarks[auth.uid]
            : null;

    return {
        auth: auth,
        profile: profile,
        recipes: recipes,
        recipesLoaded: state.firestore.data.hasOwnProperty('recipes'),
        bookMarks: bookMarks,
        bookMarksLoaded: state.firestore.data.hasOwnProperty('bookMarks'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBookMarks: (state) => {
            dispatch(setBookMarks(state));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((state) => {
        return [
            {
                collection: 'bookMarks',
                doc: state.auth.uid,
            },
            {
                collection: 'recipes',
                where: ['visibility', '==', 'public'],
                orderBy: ['creationDate', 'desc'],
            },
        ];
    })
)(Library);
