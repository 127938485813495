import { Row, Col } from 'react-materialize';
import RecipeNotifications from '../modules/notifications/recipeNotifications';
import UserNotifications from '../modules/notifications/userNotifications';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { approveNotification } from '../../store/actions/adminActions';

const Admin = (props) => {
    const {
        auth,
        recipeNotifications,
        userNotifications,
        approveNotification,
        recipes,
        users,
    } = props;

    const adminApprove = (id, notification, collection) => {
        approveNotification(id, notification, collection);
        // console.log(id, notification, collection);
    };

    if (auth && auth.uid !== 'CMZ1DNdlSLTUeZyBSok18xR01QT2') {
        return <Redirect to="/" />;
    }

    // do wwhaterver. its admin mode
    console.log(props);

    return (
        <div className="page-wrapper admin-page">
            <Row>
                <Col s={12} m={12} l={12} xl={12}>
                    <h1 className="page-heading">Admin panel</h1>
                </Col>
            </Row>

            <Row>
                <Col s={12} m={6} l={4} xl={4}>
                    <h2 className="notification-heading">
                        Recipe notifications
                    </h2>
                    {recipeNotifications && recipes && users ? (
                        <RecipeNotifications
                            recipeNotifications={recipeNotifications}
                            action={adminApprove}
                            recipes={recipes}
                            users={users}
                        />
                    ) : (
                        <p>Nothing yet, boss.</p>
                    )}
                </Col>
                <Col s={12} m={6} l={4} xl={4}>
                    <h2 className="notification-heading">User notifications</h2>
                    {userNotifications && recipes && users ? (
                        <UserNotifications
                            userNotifications={userNotifications}
                            action={adminApprove}
                            recipes={recipes}
                            users={users}
                        />
                    ) : (
                        <p>Nothing yet, boss.</p>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => {
    const recipeNotifications = state.firestore.data.recipeNotifications;
    const userNotifications = state.firestore.data.userNotifications;
    const auth = state.firebase.auth;
    const users = state.firestore.data.users;
    const recipes = state.firestore.data.recipes;

    return {
        auth: auth,
        users: users,
        recipes: recipes,
        recipeNotifications: recipeNotifications,
        userNotifications: userNotifications,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        approveNotification: (id, notification, collection) => {
            dispatch(approveNotification(id, notification, collection));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        return [
            {
                collection: 'recipeNotifications',
                where: [['approved', '==', null]],
                orderBy: ['timeCreated', 'desc'],
            },
            {
                collection: 'userNotifications',
                where: [['approved', '==', null]],
                orderBy: ['timeCreated', 'desc'],
            },
            {
                collection: 'recipes',
            },
            {
                collection: 'users',
            },
        ];
    })
)(Admin);
