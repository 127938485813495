const TermsOfUse = () => {
    return (
        <div className="page-wrapper privacy-page">
            <h1 className="banner-text">Terms of use</h1>

            <div className="rules-wrapper">
                <h2>Profiles</h2>
                <p>
                    Profile information provided will be reviewed by My Mothers
                    Recipes. Creating an account and providing information to My
                    Mothers Recipes represents user consent to review and
                    accounts may be disabled and terminted if deemed
                    inappropriate or violation of these terms of use.
                </p>

                <h2>Recipes</h2>
                <p>
                    Recipes provided to My Mothers Recipes by each user
                    represents consents to be reviewed by My Mothers Recipes.
                    Such information is open for review by My Mothers Recipes
                    and will be removed if deemed innapropriate and account
                    suspended.
                </p>

                <h2>Copyright</h2>
                <p>
                    Recipe copyright laws prohibits users misusing and
                    unlawfully using content which does not belong to them.
                    These rules and laws surrounding recipe copyright have been
                    taken into account and have been clearly listed on the
                    recipe creation page.
                </p>

                <p>
                    As a result, My Mothers Recipes does not take legal
                    resposibility for violation of these terms. Upon
                    notification of copyright infringment, My Mothers Recipes
                    will assist in removing/disabling content after reviewing
                    using in house developed tools and algorithms to determine
                    if it fits within the guidelines.
                </p>

                <h3>REMINDER: MAKE THE TOOL, WILL</h3>
            </div>
        </div>
    );
};

export default TermsOfUse;
