import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import RecipeForm from '../modules/forms/recipeForm';
// import RecipeFormTest from '../modules/recipes/recipeFormTest';
import { addRecipe } from '../../store/actions/recipeActions';
import NewRecipe from '../modules/recipes/newRecipe';
import LoadingAnimation from '../modules/loadingAnimation';
import { Row, Col } from 'react-materialize';

class AddRecipe extends Component {
    state = {
        addingRecipe: null,
    };

    formAction = response => {
        this.setState({
            addingRecipe: true,
        });
        // console.log(response);
        this.props.addRecipe(response);
    };

    render() {
        const { auth, newRecipeId, author, users } = this.props;

        const addingRecipe = this.state.addingRecipe;

        const taskStatus = newRecipeId
            ? { loadingState: '', status: 'task-complete', text: 'All done!' }
            : {
                  loadingState: 'animate-in',
                  status: 'in-progress',
                  text: 'Making recipe..',
              };

        if (!auth.uid) {
            return <Redirect to="/register" />;
        }

        if (newRecipeId) {
            setTimeout(() => {
                return this.props.history.push('/recipe/' + newRecipeId);
            }, 1500);
        }

        if (author.isLoaded && users) {
            return (
                <div className="page-wrapper add-recipe-page">
                    <Row>
                        <Col
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                            className="recipe-maker-banner">
                            <h1 className="page-heading">
                                Lets make a recipe!
                            </h1>
                            <p className="recipe-creator-teaser">
                                Start by filling out the form below.{' '}
                                <span className="hide-on-small-only">
                                    Use the checklist as a guide.
                                </span>
                                <span className="show-on-small hide-on-med-and-up">
                                    <b>Note:</b> Its much easier to use the form
                                    on desktop.
                                </span>
                            </p>
                        </Col>
                    </Row>

                    {/* <RecipeFormTest
                        recipe={NewRecipe}
                        action={this.formAction}
                        author={author}
                        createRecipe={true}
                    /> */}
                    <RecipeForm
                        recipe={NewRecipe}
                        action={this.formAction}
                        author={author}
                        createRecipe={true}
                        users={users}
                        currentUser={auth.uid}
                    />
                    {addingRecipe ? (
                        <LoadingAnimation loadingText={taskStatus} />
                    ) : null}
                </div>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = state => {
    const auth = state.firebase.auth;
    const users = state.firestore.data.users;
    const newRecipeId = state.recipe.recipeId;
    const author = state.firebase.profile;

    return {
        auth: auth,
        users: users,
        newRecipeId: newRecipeId,
        author: author,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addRecipe: recipe => {
            dispatch(addRecipe(recipe));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [{ collection: 'users' }]),
)(AddRecipe);
