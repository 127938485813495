import { useContext } from 'react';
import { Modal, Button, Icon } from 'react-materialize';
import { BookMarksContext } from '../../../store/contexts/bookMarkContext';

const DeleteListModal = (props) => {
    const { action, listNumber, toggleDeleteModalClose } = props;
    const { bookMarks, setBookMarks } = useContext(BookMarksContext);
    const pageWrapper = document.querySelector('.page-wrapper');

    const handleDeleteList = (e) => {
        e.preventDefault();
        const newList = { ...bookMarks };
        const newLibraryList = [...bookMarks.libraryLists];
        newLibraryList.splice(listNumber, 1);
        newList.libraryLists = newLibraryList;
        setBookMarks(newList);
        action(newList);
        handleExit();
    };

    const handleExit = () => {
        document.body.style.overflow = '';
    };

    return (
        <Modal
            actions={[
                <Button
                    className="flex-center browser-default close-modal"
                    modal="close"
                    node="button"
                    onClick={handleExit}
                >
                    <Icon>close</Icon>
                </Button>,
                <Button
                    className="btn browser-default right delete-actions"
                    modal="close"
                    node="button"
                    onClick={handleExit}
                >
                    Cancel
                </Button>,
                <Button
                    className="btn browser-default right delete-actions"
                    modal="close"
                    node="button"
                    onClick={handleDeleteList}
                >
                    Yes
                </Button>,
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="Delete list?"
            id="delete-list"
            className="delete-list-modal"
            open={true}
            options={{
                dismissible: true,
                endingTop: '10%',
                inDuration: 250,
                onCloseEnd: toggleDeleteModalClose,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: '4%',
            }}
            root={pageWrapper}
        >
            Are you sure you want to delete this list?
        </Modal>
    );
};

export default DeleteListModal;
