import { useState, useContext } from 'react';
import { Row, Col, Select } from 'react-materialize';
import { StepContext } from '../../../store/contexts/stepContext';
import Wysiwyg from '../forms/wysiwyg';
import RangeTimer from './rangeTimer';

const AddEditStep = (props) => {
    const { steps, dispatch } = useContext(StepContext);
    const { heading, cancelCallback, content, stepIndex, buttonText } = props;
    const [stepText, setStepText] = useState(content.content || content);
    const [newStep, setNewStep] = useState(true);
    const [stepType, setStepType] = useState(content.type || 'normal');
    const [stepTimer, setStepTimer] = useState(content.time);
    // const timeSlider = document.querySelector('.step-timer');
    const stepTypeEl = document.querySelector('.step-type');

    const handleSubmit = (e) => {
        e.preventDefault();
        const time = stepTimer;
        const fieldObject = {
            type: stepType,
            content: stepText,
        };

        if (stepType === 'timer') {
            fieldObject.time = time;
        }

        if (stepText && !stepIndex) {
            stepTypeEl.value = 'normal';
            let changeTypeEvent = null;
            if (typeof Event === 'function') {
                changeTypeEvent = new Event('change');
            } else {
                // for IE11
                changeTypeEvent = document.createEvent('Event');
                changeTypeEvent.initEvent('change', true, true);
            }
            stepTypeEl.dispatchEvent(changeTypeEvent);
            setStepText('');
            setNewStep(false);
            setStepType('normal');
            dispatch({ type: 'ADD_STEP', value: fieldObject });
            cancelCallback();
        }

        if (stepText && stepIndex) {
            dispatch({
                type: 'EDIT_STEP',
                index: stepIndex,
                value: fieldObject,
            });
            cancelCallback();
        }
    };

    const handleStepTimer = (time) => {
        setStepTimer(Math.round(time));
    };

    const handleStepTypeChange = (e) => {
        const typeValue = e.target.value;
        setStepType(typeValue);
    };

    const showTimer = stepType === 'timer' ? 'show' : 'hide';

    const callBack = (e) => {
        setStepText(e);
    };

    return (
        <Row className="step-wrapper">
            <Col s={12} m={12} l={12} xl={12}>
                <h3>{heading}</h3>
                <Select
                    s={12}
                    m={4}
                    l={3}
                    xl={3}
                    id="type"
                    className="step-type"
                    name="type"
                    multiple={false}
                    label="Type"
                    onChange={handleStepTypeChange}
                    options={{
                        classes: '',
                        dropdownOptions: {
                            alignment: 'left',
                            autoTrigger: true,
                            closeOnClick: true,
                            constrainWidth: true,
                            coverTrigger: true,
                            hover: false,
                            inDuration: 150,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 250,
                        },
                    }}
                    value={stepType}
                >
                    <option value="normal">Normal</option>
                    <option value="timer">Timer</option>
                </Select>
                <p className="step-hint col s12 m8 l9 xl9">
                    A timer step provides a special timer for this step when
                    cooking! Its helpful to still write the time on the step.
                    E.g Fry the eggs for 5mins
                </p>
                <Col
                    s={12}
                    m={12}
                    l={9}
                    xl={9}
                    className={'range-wrapper ' + showTimer}
                >
                    <RangeTimer
                        sliderId="stepTimeText"
                        labelText="Step timer:"
                        minimumValue="0"
                        defaultStartValue={stepTimer || '30'}
                        optionalClasses="step-timer slider-three"
                        timerNumber="three"
                        action={handleStepTimer}
                    />
                </Col>
                <Wysiwyg
                    controlType="bold"
                    content={content.content || content}
                    callBack={callBack}
                    placeholderText={
                        steps.length
                            ? 'Write another step here'
                            : 'Write text here'
                    }
                    newStep={newStep}
                />
            </Col>

            <div>
                <Row>
                    <Col s={12} m={12} l={12} xl={12}>
                        <Col
                            className="flex-center"
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        >
                            <button
                                className="btn step-add-button waves-effect waves-light"
                                type="button"
                                onClick={cancelCallback}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn item-add-btn waves-effect waves-light step-add-button"
                                type="button"
                                onClick={handleSubmit}
                            >
                                {buttonText || 'Add'}
                            </button>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Row>
    );
};

export default AddEditStep;
