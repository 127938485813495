const suggestedTags = {
    Vegetarian: null,
    Vegan: null,
    'Grandmas secret': null,
    'Mothers secret': null,
    Homecook: null,
    'Gluten free': null,
    Breakfast: null,
    Lunch: null,
    Dinner: null,
    Snack: null,
    Dessert: null,
    Baking: null,
};

export default suggestedTags;
