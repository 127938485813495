export const setBookMarks = (state) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        // Current user
        const loggedInUser = getState().firebase.auth.uid;
        // console.log(state);
        const userProfile = firestore.collection('bookMarks').doc(loggedInUser);

        userProfile
            .set(state)
            .then(() => {
                dispatch({
                    type: 'UPDATED_BOOKMARKS',
                    state,
                });
            })
            .catch((err) => {
                dispatch({
                    type: 'BOOKMARK_ERROR',
                    err,
                });
                // console.log('bye bye', err);
            });
    };
};

export const updateProfile = (state, originalState) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        const loggedInUser = getState().firebase.auth.uid;

        const newProfileObject = { ...state };

        // Easter egg
        const triedToEditHtml = 'Kitchen rat';
        const normalAcceptedClasses = [
            'New member',
            'Loves to cook',
            'Food lover',
            'Nom nom monster',
            'Overcooker',
            'Grill master',
        ];

        const isVerified = newProfileObject.verified;

        const profileTitleIsAccepted =
            !isVerified && normalAcceptedClasses.includes(triedToEditHtml)
                ? true
                : false;

        if (profileTitleIsAccepted) {
            newProfileObject.profileTitle = triedToEditHtml; // If they tried to edit html, change their title to Kitchen rat
        }

        // Clean verified class
        delete newProfileObject.verified;

        const profileHasntChanged =
            Object.entries(state).toString() ===
            Object.entries(originalState).toString()
                ? true
                : false;

        // console.log(
        //     profileHasntChanged,
        //     state,
        //     originalState,
        //     newProfileObject
        // );
        if (!profileHasntChanged) {
            const profileImageUpdated =
                newProfileObject.profileImage &&
                newProfileObject.profileImage.name
                    ? true
                    : false;

            const updateProfile = async () => {
                const promise = new Promise((resolve, reject) => {
                    if (profileImageUpdated) {
                        // File
                        const file = newProfileObject.profileImage;

                        // New file name for storage
                        const fileName = Date.now();
                        const newFileName =
                            fileName + '.' + file.name.split('.').slice(-1)[0];

                        // Create the file metadata
                        const metadata = {
                            contentType: file.type,
                            customMetadata: {
                                authorId: loggedInUser,
                            },
                        };

                        // Get a reference to the storage service, which is used to create references in your storage bucket
                        const storage = firebase.storage();

                        // Create a storage reference from our storage service
                        const storageRef = storage.ref();

                        // Upload file and metadata to the object 'images/mountains.jpg'
                        const uploadTask = storageRef
                            .child('images/profile/' + newFileName)
                            .put(file, metadata);

                        // TODO: Delete old image from storage

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                            function (snapshot) {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                var progress =
                                    (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                    100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                    default:
                                }
                            },
                            function (error) {
                                let errorMessage = '';
                                // A full list of error codes is available at
                                // https://firebase.google.com/docs/storage/web/handle-errors
                                switch (error.code) {
                                    case 'storage/unauthorized':
                                        // User doesn't have permission to access the object
                                        errorMessage =
                                            "You don't have permission";
                                        break;

                                    case 'storage/canceled':
                                        // User canceled the upload
                                        errorMessage = 'Cancelled upload';
                                        break;

                                    case 'storage/unknown':
                                        // Unknown error occurred, inspect error.serverResponse
                                        errorMessage =
                                            'Unknown error occured: ' +
                                            error.serverResponse;
                                        break;
                                    default:
                                }
                                dispatch({
                                    type: 'UPLOAD_FILE_ERROR',
                                    err: errorMessage,
                                });
                                reject();
                            },
                            function () {
                                // Upload completed successfully, now we can get the download URL
                                const fileName = uploadTask.snapshot.ref.name;
                                const thumbnailString = '_125x125';

                                const fileUrl = fileName.split('.');
                                const secondToLastItemFile =
                                    fileUrl[fileUrl.length - 2] +
                                    thumbnailString;

                                fileUrl[
                                    fileUrl.length - 2
                                ] = secondToLastItemFile;

                                const thumbnailFileUrl = fileUrl.join('.');
                                newProfileObject.profileImageFileName = fileName;
                                newProfileObject.profileImageThumbmailFileName = thumbnailFileUrl;

                                if (originalState.profileImageFileName) {
                                    // Remove old profile images if exist
                                    const oldProfileImage = storageRef.child(
                                        'images/profile/' +
                                            originalState.profileImageFileName
                                    );
                                    const oldProfileThumbnailImage = storageRef.child(
                                        'images/profile/' +
                                            originalState.profileImageThumbmailFileName
                                    );

                                    oldProfileImage
                                        .delete()
                                        .then(function () {
                                            // File deleted successfully
                                        })
                                        .catch(function (error) {
                                            // Uh-oh, an error occurred!
                                        });

                                    oldProfileThumbnailImage
                                        .delete()
                                        .then(function () {
                                            // File deleted successfully
                                        })
                                        .catch(function (error) {
                                            // Uh-oh, an error occurred!
                                        });
                                }

                                uploadTask.snapshot.ref
                                    .getDownloadURL()
                                    .then(function (downloadURL) {
                                        const profileUrl = downloadURL.split(
                                            '.'
                                        );
                                        const secondToLastItem =
                                            profileUrl[profileUrl.length - 2] +
                                            thumbnailString;
                                        profileUrl[
                                            profileUrl.length - 2
                                        ] = secondToLastItem;

                                        const profileThumbnailUrl = profileUrl.join(
                                            '.'
                                        );

                                        newProfileObject.profileImage = downloadURL;
                                        newProfileObject.profileThumbnailUrl = profileThumbnailUrl;

                                        dispatch({
                                            type: 'UPLOAD_FILE_SUCCESS',
                                        });
                                        resolve();
                                    });
                            }
                        );
                        // setTimeout(() => resolve('done!'), 1000);
                    } else {
                        resolve('no thumbnail');
                    }
                });

                promise.then(() => {
                    firestore
                        .collection('users')
                        .doc(loggedInUser)
                        .update({
                            ...newProfileObject,
                        })
                        .then(() => {
                            dispatch({
                                type: 'PROFILE_UPDATED',
                            });
                        })
                        .catch((err) => {
                            dispatch({
                                type: 'PROFILE_UDPATE_ERROR',
                                err,
                            });
                        });
                });
            };
            updateProfile();
        } else {
            console.log('nothing to update, son.');
            dispatch({
                type: 'PROFILE_UPDATED',
            });
        }
    };
};

// export const setBookMarks = (state) => {
//     return (dispatch, getState, { getFirestore }) => {
//         const firestore = getFirestore();

//         // Current user
//         const loggedInUser = getState().firebase.auth.uid;
//         // console.log(state);
//         const userProfile = firestore.collection('bookMarks').doc(loggedInUser);

//         userProfile
//             .set(state)
//             .then(() => {
//                 dispatch({
//                     type: 'UPDATED_BOOKMARKS',
//                     state,
//                 });
//             })
//             .catch((err) => {
//                 dispatch({
//                     type: 'BOOKMARK_ERROR',
//                     err,
//                 });
//                 // console.log('bye bye', err);
//             });
//     };
// };
