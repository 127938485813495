const initState = {};

const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case 'APPROVE_CHANGE':
            console.log('Approved ' + action.id);
            return state;
        // case 'REJECT_CHANGE':
        //     console.log('R');
        //     break;
        // case 'UPDATED_RECIPE':
        //     console.log('recipe updated');
        //     return {
        //         ...state,
        //         updateSuccess: action.id,
        //     };
        // case 'UPDATE_RECIPE_ERROR':
        //     console.log('UPDATE ERROR', action.err);

        //     break;
        default:
    }

    return state;
};

export default adminReducer;
