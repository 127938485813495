import { useState, useContext } from 'react';
import { Col, Icon, Modal, Button } from 'react-materialize';
import AddNewList from './addNewList';
import { BookMarksContext } from '../../../store/contexts/bookMarkContext';

const AddNewListModal = (props) => {
    const { recipes } = props;
    const { bookMarks, setBookMarks } = useContext(BookMarksContext);
    const [openModal, setOpenModal] = useState(false);
    const pageWrapper = document.querySelector('.page-wrapper');

    const handleCloseButton = () => {
        setOpenModal(!openModal);
    };

    const handleSubmit = (newList) => {
        const modalContainer = document.querySelector('.create-list-modal');
        let newLibraryLists = bookMarks.libraryLists
            ? [...bookMarks.libraryLists]
            : [];
        newLibraryLists.push(newList);
        const newBookMarks = { ...bookMarks, libraryLists: newLibraryLists };
        modalContainer.classList.add('fade-out');
        setOpenModal(!openModal);
        setBookMarks(newBookMarks);
    };

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    return (
        <Col s={12} m={4} l={3} xl={3} className="flex-center">
            {openModal && (
                <Modal
                    actions={[
                        <Button
                            className="flex-center browser-default right"
                            modal="close"
                            node="button"
                        >
                            <Icon>close</Icon>
                        </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="Add new list"
                    id="create-list"
                    className="create-list-modal"
                    open={openModal}
                    options={{
                        dismissible: true,
                        endingTop: '10%',
                        inDuration: 250,
                        onCloseEnd: null,
                        onCloseStart: handleCloseButton,
                        onOpenEnd: null,
                        onOpenStart: null,
                        opacity: 0.5,
                        outDuration: 250,
                        preventScrolling: true,
                        startingTop: '4%',
                    }}
                    root={pageWrapper}
                >
                    <AddNewList recipes={recipes} handleSubmit={handleSubmit} />
                </Modal>
            )}

            <Button
                className="flex-center add-list browser-default clean"
                onClick={handleOpenModal}
            >
                <Icon>add</Icon>Add new list
            </Button>
        </Col>
    );
};

export default AddNewListModal;
