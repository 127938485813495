import { useState, useEffect, useRef } from 'react';
import { Row, Col, Chip, Icon } from 'react-materialize';

const AddingTags = (props) => {
    const { updateTags, currentTags, suggestedTags, limit } = props;
    const onMount = useRef(true);
    const [tags, setTags] = useState(currentTags);

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            updateTags('tags', tags);
        }
    }, [tags, updateTags]);

    const setTagsObject = (tags) => {
        let newArray = [];
        const currentTags = tags && tags.length ? tags : [];
        currentTags.forEach((tag) => {
            const singleTag = { tag: tag };
            newArray.push(singleTag);
        });
        return newArray;
    };

    const defaultTags = setTagsObject(tags);
    const tagCounter = tags ? tags.length : 0;

    const handleAddRemoveTags = () => {
        const newTags = [];
        const currentChips = document.querySelectorAll('.recipe-tags .chip');

        currentChips.forEach((chip, i) => {
            const textValue = chip.childNodes[0].nodeValue;
            if (!newTags.includes(textValue)) {
                newTags.push(textValue);
            } else {
                newTags.splice(i, 1);
            }
        });
        newTags.sort();

        setTags(newTags);
    };

    const tagReachLimit = tagCounter === limit ? ' max-tags' : '';

    return (
        <Row>
            <Col s={12} m={12} l={12} xl={12}>
                <label className="tags-wrapper">
                    <span className="tags-label">
                        You're only allowed {limit} so make them count! Start
                        typing and feel free to use some of the suggestions for
                        inspiration
                    </span>
                    <Chip
                        close={false}
                        className={'recipe-tags' + tagReachLimit}
                        closeIcon={<Icon className="close">close</Icon>}
                        options={{
                            data: defaultTags,
                            limit: limit,
                            placeholder: 'Write a tag',
                            maxLength: 10,
                            secondaryPlaceholder: 'Add another tag',
                            autocompleteOptions: {
                                data: suggestedTags,
                                limit: Infinity,
                                minLength: 1,
                            },
                            onChipAdd: handleAddRemoveTags,
                            onChipDelete: handleAddRemoveTags,
                        }}
                    />
                    <span className="tag-counter-wrapper">
                        Tags used: {tagCounter}/{limit}
                    </span>
                </label>
            </Col>
        </Row>
    );
};

export default AddingTags;
