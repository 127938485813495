import { NavLink } from 'react-router-dom';
import { Row, Col, Icon } from 'react-materialize';

const ListMembers = (props) => {
    const { users } = props;

    const listUsers =
        users &&
        Object.keys(users).map((user, i) => {
            const profile = users[user];
            const profileId = profile && profile.alias ? profile.alias : user;
            const hasProfileImage = profile.profileImage;
            return (
                <NavLink
                    className="recipe-profile-link"
                    to={'/' + profileId}
                    title={'Visit ' + profile.displayName + "'s profile"}
                    key={i}
                >
                    <Col s={6} m={4} l={3} xl={2} className="author-wrapper">
                        {/* <Col> */}
                        <div>
                            <div className="author-thumbnail-frame">
                                {hasProfileImage ? (
                                    <img
                                        className="profile-preview"
                                        src={profile.profileThumbnailUrl}
                                        alt={'Image of ' + profile.displayName}
                                    />
                                ) : (
                                    <Icon className="no-avatar">person</Icon>
                                )}
                            </div>
                        </div>
                        {/* </Col> */}
                        <div className="author-block">
                            <span className="author-name">
                                {profile.displayName}
                            </span>
                            {/* <span className="author-title">
                                {profile.profileTitle
                                    ? profile.profileTitle
                                    : 'New member'}
                            </span> */}
                        </div>
                    </Col>
                </NavLink>
            );
        });

    return listUsers ? (
        <Row>
            <Col s={12} m={12} l={12} xl={12} className="food-people">
                <h3 className="new-food-people">Newest food people</h3>
                {listUsers}
            </Col>
        </Row>
    ) : null;
};

export default ListMembers;
