const initState = {};

const recipeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_RECIPE':
            console.log('Added recipe');
            return {
                ...state,
                recipeId: action.res.id,
            };
        case 'ADD_RECIPE_ERROR':
            console.log('CREATION ERROR', action.err);
            break;
        case 'UPDATED_RECIPE':
            console.log('recipe updated');
            return {
                ...state,
                updateSuccess: action.id,
            };
        case 'UPDATE_RECIPE_ERROR':
            console.log('UPDATE ERROR', action.err);

            break;
        default:
    }

    return state;
};

export default recipeReducer;
