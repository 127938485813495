import { useState, useEffect, useRef } from 'react';
import { Select, Row, Col, TextInput, Icon, Chip } from 'react-materialize';
import debounce from 'lodash/debounce';
import ImageUploader from './imageUploader';

const SpecialMentions = (props) => {
    const { users, specialMention, action, currentUser } = props;
    const [mentionType, setMentionType] = useState('user');
    const [userMention, setUserMention] = useState(specialMention); // add props
    const [filteredUsersList, setFilteredUsersList] = useState();
    const [customMentionThumbnail, setCustomMentionThumbnail] = useState();
    const [customMentionName, setCustomMentionName] = useState();
    const [customMentionBlurb, setCustomMentionBlurb] = useState();

    const onMount = useRef(true);

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            if (mentionType === 'custom') {
                const specialMentionObject = {
                    title: customMentionName,
                    shortDescription: customMentionBlurb,
                    mentionThumbnail: customMentionThumbnail,
                };
                action('specialMention', specialMentionObject);
                setUserMention(null);
                setFilteredUsersList();
                // console.log('test 1');
            }

            if (mentionType === 'user') {
                action('specialMention', userMention);
                setCustomMentionThumbnail();
                setCustomMentionName();
                setCustomMentionBlurb();
                // console.log('test 2');
            }
        }
    }, [
        customMentionThumbnail,
        customMentionName,
        customMentionBlurb,
        userMention,
        mentionType,
        action,
    ]);

    const searchList =
        users &&
        Object.keys(users).map((user) => {
            const userDetails = users[user];

            if (user !== currentUser) {
                return {
                    id: user,
                    fullName:
                        userDetails.firstName.toLowerCase() +
                        userDetails.lastName.toLowerCase(),
                };
            } else {
                return undefined;
            }
        });

    const handleMentionTypeChange = (e) => {
        e.preventDefault();
        setMentionType(e.target.value);
    };

    const handleUserSearch = (e, i) => {
        e.preventDefault();
        setStateValue(e.target.value);
    };

    const handleMentionThumbnailChange = debounce((value) => {
        setCustomMentionThumbnail(value);
    }, 300);

    const handleMentionNameChange = debounce((value) => {
        setCustomMentionName(value);
    }, 300);

    const handleMentionBlurbChange = debounce((value) => {
        setCustomMentionBlurb(value);
    }, 300);

    const setStateValue = debounce((value) => {
        if (value.length > 1) {
            const allUsers = [...searchList].filter(
                (item) => item !== undefined
            );

            const userMatchers = allUsers.filter((data) => {
                if (data.fullName.includes(value.toLowerCase())) {
                    return data;
                }
                return null;
            });

            setFilteredUsersList(userMatchers);
        } else {
            setFilteredUsersList();
        }
    }, 300);

    const handleOnMentionSelect = (e) => {
        e.preventDefault();
        setUserMention(e.target.value);
    };

    const handleRemoveMention = () => {
        setUserMention(null);
        setFilteredUsersList();
    };

    const filteredUsersResults =
        filteredUsersList &&
        filteredUsersList.map((user, i) => {
            const individualUser = users[user.id];
            const hasProfileImage = individualUser.profileThumbnailUrl;

            return (
                <li key={i}>
                    <button
                        className="btn browser-default clean mention-btn"
                        value={user.id}
                        onClick={handleOnMentionSelect}
                    >
                        {individualUser.firstName} {individualUser.lastName}
                        <div className="author-thumbnail-frame">
                            {hasProfileImage ? (
                                <img
                                    className="profile-preview"
                                    src={hasProfileImage}
                                    alt={
                                        'Image of ' + individualUser.displayName
                                    }
                                />
                            ) : (
                                <Icon className="no-avatar">person</Icon>
                            )}
                        </div>
                    </button>
                </li>
            );
        });

    const userMentionChip =
        userMention && users[userMention] ? (
            <>
                <Chip
                    close
                    closeIcon={<Icon className="close">close</Icon>}
                    options={null}
                    className="mention-chip"
                    onClick={handleRemoveMention}
                >
                    <div className="author-thumbnail-frame">
                        {users[userMention].profileThumbnailUrl ? (
                            <img
                                alt={
                                    users[userMention].firstName +
                                    ' ' +
                                    users[userMention].lastName
                                }
                                className="responsive-img"
                                src={users[userMention].profileThumbnailUrl}
                            />
                        ) : (
                            <Icon className="no-avatar">person</Icon>
                        )}
                    </div>
                    <span className="chip-name">
                        {users[userMention].firstName}{' '}
                        {users[userMention].lastName}
                    </span>
                </Chip>
            </>
        ) : null;

    // console.log(filteredUsersResults);
    // console.log(mentionType);
    // console.log(userMention);

    return (
        <Row>
            <Select
                s={12}
                m={3}
                l={3}
                xl={3}
                id="type"
                className="mention-type"
                name="type"
                multiple={false}
                label="Type"
                onChange={handleMentionTypeChange}
                options={{
                    classes: '',
                    dropdownOptions: {
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        coverTrigger: true,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                    },
                }}
                defaultValue={mentionType}
            >
                <option value="user">Existing user</option>
                <option value="custom">Custom</option>
            </Select>

            {mentionType === 'user' && !userMention && (
                <TextInput
                    s={12}
                    m={4}
                    l={3}
                    xl={3}
                    id="userId"
                    autoComplete="off"
                    onChange={handleUserSearch}
                    placeholder="Search for user"
                />
            )}
            {filteredUsersResults && !userMention && (
                <Col s={12} m={5} l={6} xl={6} className="user-search">
                    <h3 className="user-search-title">Users found:</h3>
                    {filteredUsersResults.length ? (
                        <ul className="filtered-users">
                            {filteredUsersResults}
                        </ul>
                    ) : (
                        <span className="no-users-found">0 matches</span>
                    )}
                </Col>
            )}

            {mentionType === 'user' && userMention && (
                <Col s={12} m={5} l={6} xl={6}>
                    <p className="user-selected">User selected: </p>
                    {userMentionChip}
                </Col>
            )}

            {mentionType === 'custom' && (
                <>
                    <TextInput
                        s={12}
                        m={4}
                        l={4}
                        xl={4}
                        id="customMentionName"
                        name="customMentionName"
                        autoComplete="off"
                        data-length={20}
                        maxLength="20"
                        onChange={(e) =>
                            handleMentionNameChange(e.target.value)
                        }
                        label="Title"
                    />
                    <TextInput
                        s={12}
                        m={5}
                        l={5}
                        xl={5}
                        data-length={25}
                        maxLength="25"
                        id="customMentionBlurb"
                        name="customMentionBlurb"
                        autoComplete="off"
                        onChange={(e) =>
                            handleMentionBlurbChange(e.target.value)
                        }
                        label="Short description"
                    />
                    <ImageUploader
                        imageProp="customMentionThumbnail"
                        action={handleMentionThumbnailChange}
                    />
                </>
            )}
        </Row>
    );
};

export default SpecialMentions;
