import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import RecipeForm from '../modules/forms/recipeForm';
import { Row, Col } from 'react-materialize';
import { updateRecipe } from '../../store/actions/recipeActions';
import LoadingAnimation from '../modules/loadingAnimation';

class EditRecipe extends Component {
    state = {
        updatingRecipe: null,
    };
    formAction = (response) => {
        this.setState({
            updatingRecipe: true,
        });

        this.props.updateRecipe(
            this.props.recipeId,
            this.props.recipe,
            response
        );
        // console.log(response);
    };

    render() {
        const {
            recipeId,
            auth,
            recipe,
            author,
            recipeError,
            updateSuccess,
            users,
        } = this.props;

        const updatingRecipe = this.state.updatingRecipe;

        const taskStatus = updateSuccess
            ? {
                  loadingState: '',
                  status: 'task-complete',
                  text: 'All done!',
              }
            : {
                  loadingState: 'animate-in',
                  status: 'in-progress',
                  text: 'Updating recipe',
              };

        if (recipeError) {
            return (
                <h1 className="accessdenied">Sorry, you can't edit this :(</h1>
            );
        }

        if (updateSuccess && updatingRecipe) {
            setTimeout(() => {
                return this.props.history.push('/recipe/' + recipeId);
            }, 1300);
        }

        if (recipe && author && users) {
            const viewerIsAuthor = recipe.authorId === auth.uid;

            if (!viewerIsAuthor) {
                return <Redirect to="/" />;
            }

            return (
                <div className="edit-recipe-page page-wrapper">
                    <Row>
                        <Col
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                            className="recipe-maker-banner"
                        >
                            <h1 className="page-heading">Edit recipe</h1>
                            <p className="recipe-creator-teaser">
                                You know the drill.{' '}
                                <span className="hide-on-small-only">
                                    Use the checklist as a guide.
                                </span>
                            </p>
                        </Col>
                    </Row>
                    <RecipeForm
                        recipe={recipe}
                        author={author}
                        action={this.formAction}
                        taskStatus={taskStatus}
                        createRecipe={false}
                        users={users}
                        currentUser={auth.uid}
                    />
                    {updatingRecipe && (
                        <LoadingAnimation loadingText={taskStatus} />
                    )}
                </div>
            );
        } else {
            // return <LoadingAnimation />;
            return <></>;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.recipe_id;
    const auth = state.firebase.auth;
    const recipes = state.firestore.data.recipes;
    const recipe = recipes ? recipes[id] : null;
    const users = state.firestore.data.users;
    const author = users && recipe ? users[recipe.authorId] : null;
    const error = state.firestore.errors
        ? state.firestore.errors.byQuery['recipes/' + id]
        : null;
    const updateSuccess = state.recipe.updateSuccess;
    return {
        recipeId: id,
        recipe: recipe,
        users: users,
        author: author,
        recipeError: error,
        updateSuccess: updateSuccess,
        auth: auth,
    };
};

// ADD mapDispatchToProps
const mapDispatchToProps = (dispatch) => {
    return {
        updateRecipe: (id, originalRecipe, recipeUpdates) => {
            dispatch(updateRecipe(id, originalRecipe, recipeUpdates));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((state) => [
        {
            collection: 'recipes',
            doc: state.match.params.recipe_id,
        },
        { collection: 'users' },
    ])
)(EditRecipe);
