export const addRecipe = (recipe) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const authorId = getState().firebase.auth.uid;
        let newRecipeObject = {
            ...recipe,
            authorId: authorId,
            creationDate: new Date(),
        };

        // clean object
        delete newRecipeObject.thumbnailPreviewUrl;
        delete newRecipeObject.creatingRecipe;
        delete newRecipeObject.redirect;
        delete newRecipeObject.errorMissing;

        // File
        const file = recipe.thumbnailImage;

        // New file name for storage
        const fileName = Date.now();
        const newFileName = fileName + '.' + file.name.split('.').slice(-1)[0];

        // Create the file metadata
        const metadata = {
            contentType: file.type,
            customMetadata: {
                authorId: authorId,
            },
        };

        // Get a reference to the storage service, which is used to create references in your storage bucket
        const storage = firebase.storage();

        // Create a storage reference from our storage service
        const storageRef = storage.ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        const uploadTask = storageRef
            .child('images/recipes/' + newFileName)
            .put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                }
            },
            function (error) {
                let errorMessage = '';
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        errorMessage = "You don't have permission";
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        errorMessage = 'Cancelled upload';
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        errorMessage =
                            'Unknown error occured: ' + error.serverResponse;
                        break;
                    default:
                }
                dispatch({
                    type: 'UPLOAD_FILE_ERROR',
                    err: errorMessage,
                });
            },
            function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function (downloadURL) {
                        const thumbnailString = '_125x125';
                        const thumbnailUrl = downloadURL.split('.');
                        const fileUrl = newFileName.split('.');
                        const secondToLastItem =
                            thumbnailUrl[thumbnailUrl.length - 2] +
                            thumbnailString;
                        const secondToLastItemFile =
                            fileUrl[fileUrl.length - 2] + thumbnailString;
                        thumbnailUrl[
                            thumbnailUrl.length - 2
                        ] = secondToLastItem;
                        fileUrl[fileUrl.length - 2] = secondToLastItemFile;

                        const tinyThumbnailImage = thumbnailUrl.join('.');
                        const thumbnailFileUrl = fileUrl.join('.');

                        newRecipeObject.thumbnailImage = downloadURL;
                        newRecipeObject.tinyThumbnailImage = tinyThumbnailImage;
                        newRecipeObject.thumbnailImageFileName = newFileName;
                        newRecipeObject.tinyThumbnailImageFileName = thumbnailFileUrl;

                        dispatch({
                            type: 'UPLOAD_FILE_SUCCESS',
                        });

                        firestore
                            .collection('recipes')
                            .add({
                                ...newRecipeObject,
                            })
                            .then((res) => {
                                dispatch({
                                    type: 'ADD_RECIPE',
                                    recipe,
                                    res,
                                });
                            })
                            .catch((err) => {
                                dispatch({
                                    type: 'ADD_RECIPE_ERROR',
                                    err,
                                });
                            });
                    });
            }
        );
    };
};

export const updateRecipe = (id, initialState, newState) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        const authorId = getState().firebase.auth.uid;

        const originalRecipeObject = initialState;
        const newRecipeObject = newState;

        let updateRecipeObject = { ...newRecipeObject };

        // Compare original object with new and filter out duplicates from update object
        Object.keys(originalRecipeObject).forEach((attr) => {
            if (originalRecipeObject[attr] === newRecipeObject[attr]) {
                delete updateRecipeObject[attr];
            }
        });

        // Exclude update authorId
        delete updateRecipeObject.authorId;

        // Check if logged in user matches authorId
        const isAuthor =
            originalRecipeObject.authorId === authorId ? true : false;

        if (isAuthor) {
            const thumbnailUpdated = updateRecipeObject.thumbnailImage;
            const updateRecipe = async () => {
                let promise = new Promise((resolve, reject) => {
                    if (thumbnailUpdated) {
                        // File
                        const file = updateRecipeObject.thumbnailImage;

                        // New file name for storage
                        const fileName = Date.now();
                        const newFileName =
                            fileName + '.' + file.name.split('.').slice(-1)[0];

                        // Create the file metadata
                        const metadata = {
                            contentType: file.type,
                            customMetadata: {
                                authorId: authorId,
                            },
                        };

                        // Get a reference to the storage service, which is used to create references in your storage bucket
                        const storage = firebase.storage();

                        // Create a storage reference from our storage service
                        const storageRef = storage.ref();

                        // Upload file and metadata to the object 'images/mountains.jpg'
                        const uploadTask = storageRef
                            .child('images/recipes/' + newFileName)
                            .put(file, metadata);

                        // TODO: Delete old image from storage

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                            function (snapshot) {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                var progress =
                                    (snapshot.bytesTransferred /
                                        snapshot.totalBytes) *
                                    100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                    default:
                                }
                            },
                            function (error) {
                                let errorMessage = '';
                                // A full list of error codes is available at
                                // https://firebase.google.com/docs/storage/web/handle-errors
                                switch (error.code) {
                                    case 'storage/unauthorized':
                                        // User doesn't have permission to access the object
                                        errorMessage =
                                            "You don't have permission";
                                        break;

                                    case 'storage/canceled':
                                        // User canceled the upload
                                        errorMessage = 'Cancelled upload';
                                        break;

                                    case 'storage/unknown':
                                        // Unknown error occurred, inspect error.serverResponse
                                        errorMessage =
                                            'Unknown error occured: ' +
                                            error.serverResponse;
                                        break;
                                    default:
                                }
                                dispatch({
                                    type: 'UPLOAD_FILE_ERROR',
                                    err: errorMessage,
                                });
                            },
                            function () {
                                // Upload completed successfully, now we can get the download URL
                                const fileName = uploadTask.snapshot.ref.name;
                                const thumbnailString = '_125x125';

                                const fileUrl = fileName.split('.');
                                const secondToLastItemFile =
                                    fileUrl[fileUrl.length - 2] +
                                    thumbnailString;

                                fileUrl[
                                    fileUrl.length - 2
                                ] = secondToLastItemFile;

                                const thumbnailFileUrl = fileUrl.join('.');
                                updateRecipeObject.thumbnailImageFileName = fileName;
                                updateRecipeObject.tinyThumbnailImageFileName = thumbnailFileUrl;

                                if (
                                    originalRecipeObject.thumbnailImageFileName
                                ) {
                                    // Remove old profile images if exist
                                    const oldThumbnailImage = storageRef.child(
                                        'images/recipes/' +
                                            originalRecipeObject.thumbnailImageFileName
                                    );
                                    const oldTinyThumbnailImage = storageRef.child(
                                        'images/recipes/' +
                                            originalRecipeObject.tinyThumbnailImageFileName
                                    );

                                    oldThumbnailImage
                                        .delete()
                                        .then(function () {
                                            // File deleted successfully
                                        })
                                        .catch(function (error) {
                                            // Uh-oh, an error occurred!
                                        });

                                    oldTinyThumbnailImage
                                        .delete()
                                        .then(function () {
                                            // File deleted successfully
                                        })
                                        .catch(function (error) {
                                            // Uh-oh, an error occurred!
                                        });
                                }

                                uploadTask.snapshot.ref
                                    .getDownloadURL()
                                    .then(function (downloadURL) {
                                        const thumbnailString = '_125x125';
                                        const thumbnailUrl = downloadURL.split(
                                            '.'
                                        );
                                        const secondToLastItem =
                                            thumbnailUrl[
                                                thumbnailUrl.length - 2
                                            ] + thumbnailString;
                                        thumbnailUrl[
                                            thumbnailUrl.length - 2
                                        ] = secondToLastItem;

                                        const tinyThumbnailImage = thumbnailUrl.join(
                                            '.'
                                        );

                                        updateRecipeObject.thumbnailImage = downloadURL;
                                        updateRecipeObject.tinyThumbnailImage = tinyThumbnailImage;

                                        dispatch({
                                            type: 'UPLOAD_FILE_SUCCESS',
                                        });
                                        resolve();
                                    });
                            }
                        );
                    } else {
                        console.log('No thumbnail');
                        resolve('No thumbnail');
                    }
                });

                promise.then(() => {
                    firestore
                        .collection('recipes')
                        .doc(id)
                        .update({
                            ...updateRecipeObject,
                        })
                        .then(() => {
                            dispatch({
                                type: 'UPDATED_RECIPE',
                                id,
                            });
                        })
                        .catch((err) => {
                            dispatch({
                                type: 'UPDATE_RECIPE_ERROR',
                                err,
                            });
                        });
                });
            };
            updateRecipe();
        } else {
            console.log('Nothing to update, son.');
        }
    };
};
