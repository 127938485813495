import { Row, Col, Icon } from 'react-materialize';
import ShowTags from '../recipes/showTags';

const ProfileLayout = ({ profile }) => {
    return (
        <Row>
            <Col
                className="profile-banner center-align"
                s={12}
                m={12}
                l={12}
                xl={12}
            >
                <div className="profile-avatar-wrapper">
                    {profile.profileImage ? (
                        <img
                            className="profile-preview"
                            src={profile.profileImage}
                            alt={profile.displayName}
                        />
                    ) : (
                        <Icon className="no-avatar">person</Icon>
                    )}
                </div>

                <h1 className="profile-banner-title">
                    {profile.displayName
                        ? profile.displayName
                        : profile.firstName + ' ' + profile.lastName}
                    {profile.verified && (
                        <span className="verified-user" title="Verified user">
                            <Icon className="verified-check">check_circle</Icon>
                        </span>
                    )}
                </h1>
                <p className="profile-title">
                    {profile.profileTitle ? profile.profileTitle : 'New member'}
                </p>

                {profile.tags && profile.tags.length ? (
                    <ShowTags tags={profile.tags} />
                ) : null}
            </Col>
        </Row>
    );
};

export default ProfileLayout;
