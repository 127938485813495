import { useState, useContext } from 'react';
import { Row, Col, Icon, Button, TextInput } from 'react-materialize';
import debounce from 'lodash/debounce';
import { BookMarksContext } from '../../../store/contexts/bookMarkContext';

const AddNewList = (props) => {
    const { recipes, handleSubmit, title, listRecipes } = props;
    const { bookMarks } = useContext(BookMarksContext);
    const [newListTitle, setNewListTitle] = useState(title);
    const [selectedRecipes, setSelectedRecipes] = useState(listRecipes || []);
    const availableRecipes = bookMarks && bookMarks.starred;

    const handleOnChange = (e) => {
        e.preventDefault();
        setStateValue(e.target.value);
    };

    const setStateValue = debounce((value) => {
        setNewListTitle(value);
    }, 300);

    const handleAddToList = (e) => {
        e.preventDefault();
        const newRecipeId = e.target.value;
        const existInList = selectedRecipes.includes(newRecipeId);
        // const isSelected = e.target.classList;

        if (existInList) {
            setSelectedRecipes(
                selectedRecipes.filter((recipe) => recipe !== newRecipeId)
            );
        } else {
            setSelectedRecipes((selectedRecipes) => [
                ...selectedRecipes,
                newRecipeId,
            ]);
        }
        // Remove from available list
        // setAvailableRecipes(
        //     availableRecipes.filter((recipe) => recipe !== newRecipeId)
        // );

        // Add to selected list
    };

    const handleRemoveFromList = (e) => {
        e.preventDefault();
        const recipeId = e.target.value;

        // Remove from selected list
        setSelectedRecipes(
            selectedRecipes.filter((recipe) => recipe !== recipeId)
        );
    };

    const handleCreateNewList = () => {
        const newListToAdd = { recipes: selectedRecipes, title: newListTitle };
        const titleInput = document.querySelector('.list-title-input');
        handleSubmit(newListToAdd);
        document.body.style.overflow = '';
        setNewListTitle('');
        titleInput.value = '';
        setSelectedRecipes([]);
    };

    const availableRecipeItems = availableRecipes.map((recipeId, i) => {
        const recipeTitle =
            recipes && recipes[recipeId] && recipes[recipeId].title;
        const isSelected = selectedRecipes.includes(recipeId)
            ? 'item-selected hide-on-med-and-up'
            : '';

        return recipes[recipeId] ? (
            <li key={i} className={isSelected}>
                <Button
                    className="list-action browser-default"
                    onClick={handleAddToList}
                    value={recipeId}
                >
                    <Icon>add</Icon> {recipeTitle}
                </Button>
            </li>
        ) : (
            ''
        );
    });

    const selectedRecipeItems = selectedRecipes
        ? selectedRecipes.map((recipeId, i) => {
              const recipeTitle = recipes && recipes[recipeId].title;
              return (
                  <li key={i}>
                      <Button
                          className="list-action browser-default"
                          onClick={handleRemoveFromList}
                          value={recipeId}
                      >
                          <Icon>remove</Icon> {recipeTitle}
                      </Button>
                  </li>
              );
          })
        : null;

    return (
        <>
            <Row>
                <TextInput
                    id="list-title-input"
                    className="list-title-input validate"
                    placeholder="Write a list title"
                    type="text"
                    name="newListTitle"
                    autoComplete="off"
                    onChange={handleOnChange}
                    required
                    data-length={20}
                    maxLength="20"
                    defaultValue={newListTitle}
                    s={12}
                    m={6}
                    l={6}
                    xl={6}
                />
            </Row>

            {newListTitle ? (
                <Row className="create-list-wrapper">
                    <Col
                        className="hide-on-small-only"
                        s={12}
                        m={6}
                        l={6}
                        xl={6}
                    >
                        {newListTitle ? (
                            <h3 className="new-list-title">{newListTitle}</h3>
                        ) : null}

                        {selectedRecipeItems.length ? (
                            <ul className="selection-list browser-default">
                                {selectedRecipeItems}
                            </ul>
                        ) : null}

                        {!selectedRecipeItems.length && (
                            // <Col s={12} m={6} l={6} xl={6}>
                            <p className="zero-list-items-msg">
                                0 selected. You can add items later if you want
                            </p>
                            // </Col>
                        )}
                    </Col>

                    <Col s={12} m={6} l={6} xl={6}>
                        <h3 className="selection-list-title">
                            Select recipes to add
                        </h3>
                        <ul className="selection-list browser-default">
                            {availableRecipeItems}
                        </ul>
                        {!selectedRecipeItems.length && (
                            // <Col s={12} m={6} l={6} xl={6}>
                            <p className="zero-list-items-msg show-on-small hide-on-med-and-up">
                                0 selected. You can add items later if you want
                            </p>
                            // </Col>
                        )}
                    </Col>
                </Row>
            ) : null}

            <Col s={12} m={12} l={12} xl={12} className="edit-list-controls">
                {newListTitle ? (
                    <Button
                        className="add-list-btn flex-center browser-default"
                        onClick={handleCreateNewList}
                    >
                        {listRecipes ? 'Done' : 'Create'}
                    </Button>
                ) : (
                    <Button
                        className="add-list-btn flex-center browser-default"
                        disabled
                    >
                        {listRecipes ? 'Done' : 'Create'}
                    </Button>
                )}
            </Col>
        </>
    );
};

export default AddNewList;
