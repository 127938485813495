import { createContext, useReducer } from 'react';
import { cookingTimersReducer } from '../reducers/cookingTimersReducer';

export const CookingTimersContext = createContext();

const CookingTimersContextProvider = (props) => {
    const [cookingTimers, dispatch] = useReducer(
        cookingTimersReducer,
        {},
        () => {
            return props.cookingTimers;
        }
    );

    return (
        <CookingTimersContext.Provider value={{ cookingTimers, dispatch }}>
            {props.children}
        </CookingTimersContext.Provider>
    );
};

export default CookingTimersContextProvider;
