// import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const SignedInLinks = (props) => {
    const { profileId, profile, signOut } = props;
    // const [profileImage, setProfileImage] = useState('');

    const universalId = profile && profile.alias ? profile.alias : profileId;

    const testing = () => {
        setTimeout(() => {
            document.querySelector('.profile-preview').src =
                profile.profileThumbnailUrl;
            console.log('testing');
        }, 1500);
    };

    return (
        <ul className="signedin-links">
            <li>
                <Link to={'/' + universalId} className="profile-link nav-link">
                    {profile.profileThumbnailUrl ? (
                        <div className="profile-thumbnail-frame">
                            <img
                                className="profile-preview"
                                src={
                                    // profileImage
                                    //     ? profileImage
                                    //     : profile.profileThumbnailUrl
                                    profile.profileThumbnailUrl
                                }
                                alt={'Image of ' + profile.displayName}
                                onError={testing}
                            />
                        </div>
                    ) : (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-user"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#000000"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                            <span className="nav-text hide-on-small-only">
                                Profile
                            </span>
                        </>
                    )}
                </Link>
            </li>
            <li>
                <Link to="/addrecipe" title="Add a recipe" className="">
                    Add a recipe
                </Link>
            </li>
            <li>
                <Link to="/profile/edit" title="Edit profile" className="">
                    Edit profile
                </Link>
            </li>

            <li>
                <Link to="/" className="sign-out" onClick={signOut}>
                    <span>Sign out</span>
                </Link>
            </li>
            {/* <li>
                <a href="/" className="signout-link" onClick={props.signOut}>
                    <Icon>subdirectory_arrow_right</Icon>
                    <span>sign out</span>
                </a>
            </li> */}
        </ul>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
