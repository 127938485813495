import Navigation from './components/modules/navigation';
import { Switch, Route, useLocation } from 'react-router-dom';
import Friends from './components/pages/Friends';
import Home from './components/pages/Home';
import Profile from './components/pages/Profile';
import EditProfile from './components/pages/EditProfile';
import Recipe from './components/pages/Recipe';
import EditRecipe from './components/pages/EditRecipe';
import AddRecipe from './components/pages/AddRecipe';
import Library from './components/pages/Library';
import Privacy from './components/pages/Privacy';
import TermsOfUse from './components/pages/TermsOfUse';
import SignedInLinks from './components/layout/signedInLinks';
import SignedOutLinks from './components/layout/signedOutLinks';
import Login from './components/auth/login';
import Register from './components/auth/register';
import RecoverPassword from './components/auth/recoverpassword';
import ChangePassword from './components/auth/changepassword';
import Admin from './components/pages/Admin';
import { connect } from 'react-redux';
import ScrollToTop from './scrollToTop';
import SocialMedia from './components/layout/socialMedia';
import FooterLinks from './components/layout/footerLinks';

const App = (props) => {
    const { signedOut, profileId, profile } = props;
    const location = useLocation();
    const accountControls = signedOut ? (
        <SignedOutLinks />
    ) : (
        <SignedInLinks profileId={profileId} profile={profile} />
    );

    return (
        <div className="app">
            <Navigation />
            <div className="login-controls temp-hide">{accountControls}</div>
            <ScrollToTop>
                <Switch location={location} key={location.key}>
                    <Route exact path="/" component={Home} />
                    <Route path="/friends" component={Friends} />
                    <Route exact path="/profile/edit" component={EditProfile} />
                    <Route path="/profile/:profile_id" component={Profile} />
                    <Route path="/recipe/:recipe_id" component={Recipe} />
                    <Route path="/recipe*" component={Recipe} />
                    <Route
                        path="/editrecipe/:recipe_id"
                        component={EditRecipe}
                    />
                    <Route path="/library" component={Library} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/addrecipe" component={AddRecipe} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/termsofuse" component={TermsOfUse} />
                    <Route
                        path="/recoverpassword"
                        component={RecoverPassword}
                    />
                    <Route path="/changepassword*" component={ChangePassword} />
                    <Route path="/:profile_id" component={Profile} />
                </Switch>
            </ScrollToTop>
            <footer>
                <SocialMedia />
                <FooterLinks />
                <p className="temp-footer delayed-fadein">made by will.</p>
            </footer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        signedOut: state.firebase.auth.isEmpty,
        profileId: state.firebase.auth.uid,
        profile: state.firebase.profile,
    };
};

export default connect(mapStateToProps)(App);
