const initState = {};

const starringReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATED_BOOKMARKS':
            console.log('Updated bookmarks');
            return state;

        case 'BOOKMARK_ERROR':
            console.log('Error adding to bookmarks', action.err);
            return state;
        default:
    }
    return state;
};

export default starringReducer;
