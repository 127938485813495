export const stepReducer = (state, action) => {
    let newItemList = [...state];
    switch (action.type) {
        case 'ADD_STEP':
            return [...state, action.value];

        case 'EDIT_STEP':
            newItemList.splice(action.index, 1, action.value);
            return newItemList;

        case 'REMOVE_STEP':
            newItemList.splice(action.index, 1);
            return newItemList;

        default:
    }
};
