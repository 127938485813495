import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import RecipeLayout from '../modules/recipes/recipeLayout';
import IngredientContextProvider from '../../store/contexts/ingredientContext';
import StepContextProvider from '../../store/contexts/stepContext';
import CookingTimersContextProvider from '../../store/contexts/cookingTimersContext';
import BookMarkContextProvider from '../../store/contexts/bookMarkContext';
import { setBookMarks } from '../../store/actions/userActions';

class Recipe extends Component {
    render() {
        const {
            recipeId,
            recipe,
            author,
            recipeError,
            auth,
            setBookMarks,
            bookMarks,
            bookMarksLoaded,
            profile,
            users,
        } = this.props;
        const liveMode = true;
        const isCooking = localStorage.getItem('cooking');
        const ingredientsState = localStorage.getItem('ingredients');
        const cookingTimers =
            isCooking && localStorage.getItem('cookingTimers')
                ? JSON.parse(localStorage.getItem('cookingTimers'))
                : {};

        if (!recipeId) {
            return <Redirect to="/" />;
        }

        if (recipeError) {
            return (
                <h1 className="accessdenied">
                    Sorry, this recipe is private :(
                </h1>
            );
        }

        // Load recipe once both author and recipe are loaded
        if (
            recipe &&
            author &&
            auth.isLoaded &&
            bookMarksLoaded &&
            profile &&
            users
        ) {
            const viewerIsAuthor =
                recipe.authorId === auth.uid ? 'author-active' : '';

            return (
                <IngredientContextProvider ingredients={recipe.ingredients}>
                    <StepContextProvider steps={recipe.steps}>
                        <CookingTimersContextProvider
                            cookingTimers={cookingTimers}
                        >
                            <BookMarkContextProvider bookMarks={bookMarks}>
                                {viewerIsAuthor && (
                                    <p className="author-toolbar center-align temp-hide">
                                        This is your recipe!{' '}
                                        <Link
                                            className="edit-recipe-link"
                                            to={'/editrecipe/' + recipeId}
                                            title={'Edit ' + recipe.title}
                                        >
                                            Edit recipe
                                        </Link>
                                    </p>
                                )}
                                <div
                                    className={
                                        'recipe-page page-wrapper ' +
                                        viewerIsAuthor
                                    }
                                >
                                    <RecipeLayout
                                        recipeId={recipeId}
                                        recipe={recipe}
                                        author={author}
                                        viewerIsAuthor={viewerIsAuthor}
                                        isLoggedIn={auth.uid}
                                        liveMode={liveMode}
                                        isCooking={isCooking}
                                        ingredientsState={ingredientsState}
                                        setBookMarks={setBookMarks}
                                        users={users}
                                    />
                                </div>
                            </BookMarkContextProvider>
                        </CookingTimersContextProvider>
                    </StepContextProvider>
                </IngredientContextProvider>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.recipe_id;
    const auth = state.firebase.auth;
    const recipes = state.firestore.data.recipes;
    const recipe = recipes ? recipes[id] : null;
    const users = state.firestore.data.users;
    const bookMarksLoaded = state.firestore.data.bookMarks;
    const bookMarks =
        auth && bookMarksLoaded ? state.firestore.data.bookMarks[auth.uid] : {};
    const author = users && recipe ? users[recipe.authorId] : null;
    const error = state.firestore.errors
        ? state.firestore.errors.byQuery['recipes/' + id]
        : null;

    const profile = state.firebase.profile.isLoaded;

    return {
        recipeId: id,
        recipe: recipe,
        author: author,
        users: users,
        auth: auth,
        error: error,
        bookMarksLoaded: bookMarksLoaded,
        bookMarks: bookMarks,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBookMarks: (state) => {
            dispatch(setBookMarks(state));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((state) => [
        {
            collection: 'recipes',
            doc: state.match.params.recipe_id,
        },
        { collection: 'users' },
        {
            collection: 'bookMarks',
            doc: state.auth.uid,
        },
    ])
)(Recipe);

// export default Recipe;
