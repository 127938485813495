import { useContext } from 'react';
import 'materialize-css';
import './index.scss';
import {
    Row,
    Col,
    Icon,
    MediaBox,
    Collapsible,
    CollapsibleItem,
} from 'react-materialize';
import ConvertToReadableTime from '../makerecipe/readableTime';
import { NavLink } from 'react-router-dom';
import '../makerecipe/index.scss';
import Glide, {
    Controls,
    Breakpoints,
    Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';

// New
import { IngredientContext } from '../../../store/contexts/ingredientContext';
import IngredientsListLayout from '../ingredients/ingredientsListLayout';

import { StepContext } from '../../../store/contexts/stepContext';
import StepsListLayout from '../steps/stepsListLayout';

// import { CookingTimersContext } from '../../../store/contexts/cookingTimersContext';
// import { BookMarksContext } from '../../../store/contexts/bookMarkContext';

import Starring from './starring';
import ShowTags from './showTags';

const RecipeLayout = (props) => {
    const {
        recipeId,
        recipe,
        author,
        viewerIsAuthor,
        liveMode,
        isCooking,
        ingredientsState,
        setBookMarks,
        isLoggedIn,
        users,
    } = props;
    const { ingredients } = useContext(IngredientContext);
    const { steps } = useContext(StepContext);
    // const { dispatch } = useContext(CookingTimersContext);
    let glideSlider = null;

    const stepView = (start) => {
        return new Glide('.glide', {
            perView: 3,
            bound: true,
            startAt: start ? start : 0,
            dragThreshold: 30,
            breakpoints: {
                768: {
                    perView: 1,
                },
            },
        }).mount({ Controls, Breakpoints, Swipe });
    };

    const thumbnail =
        typeof recipe.thumbnailImage === 'object'
            ? URL.createObjectURL(recipe.thumbnailImage)
            : recipe.thumbnailImage;

    const profileId = author && author.alias ? author.alias : recipe.authorId;

    const getIngredientsListState = (ingredients) => {
        const ingredientsList = document.querySelectorAll('.ingredient');
        let ingredientsArray = ingredients ? ingredients.ingredients : [];

        if (ingredientsList.length) {
            ingredientsList.forEach((ingredient, i) => {
                let ingredientState = 0;
                ingredient.classList.forEach((ingredientclass) => {
                    if (
                        ingredientclass === 'checked' ||
                        ingredientclass === 'used'
                    ) {
                        ingredientState++;
                    }
                });
                ingredientsArray.push(ingredientState);
            });
            ingredientsArray = ingredientsArray.toString();
        }
        return ingredientsArray;
    };

    const updateCookie = (ingredientsString) => {
        const ingredientsCookie = ingredientsString
            ? ingredientsString
            : getIngredientsListState();
        localStorage.setItem('ingredients', ingredientsCookie);
    };

    const handleCookingMode = (e) => {
        const cookingState = document.body.classList.contains('cooking-mode');
        const onLoad = e.onload;
        const recipeUrl = window.location.pathname;
        const checkTheme = localStorage.getItem('cm-theme');

        // Enter cooking mode
        if (!onLoad) {
            if (!cookingState) {
                // Is cooking. Set cookie
                localStorage.setItem('cooking', 'yes');
                localStorage.setItem('recipeUrl', recipeUrl);

                updateCookie();
            } else {
                // Finished cooking
                localStorage.setItem('cooking', '');
                localStorage.setItem('recipeUrl', '');
                localStorage.setItem('current-sv-step', '0');
                localStorage.setItem('cookingTimers', '');
                handleCMview({ onload: null, closeCM: true });
            }
        } else {
            handleCMview({ onload: 'true' });
        }
        if (checkTheme) {
            document.body.classList.add(checkTheme);
        }

        document.body.classList.toggle('cooking-mode');
        window.scrollTo(0, 0);
    };

    const handleCMview = (e) => {
        const onLoad = e.onload;
        const closeCM = e.closeCM ? 'destroy' : null;
        const userAction = e.target ? e.target.value : closeCM;
        const lastView = localStorage.getItem('cm-view');
        const currentSVStep = localStorage.getItem('current-sv-step');
        const cmView = onLoad ? lastView : userAction;

        if (cmView === 'step') {
            setTimeout(() => {
                glideSlider = stepView(currentSVStep);
                glideSlider.on('move.after', () => {
                    localStorage.setItem('current-sv-step', glideSlider._i);
                });
            }, 50);
            document.body.classList.add('glide-active');
        }

        if (
            (glideSlider && cmView === 'list') ||
            (glideSlider && cmView === 'destroy')
        ) {
            glideSlider.disable();
            glideSlider.destroy();
            glideSlider = null;
            document.body.classList.remove('glide-active');
            localStorage.setItem('current-sv-step', '0');
        }
        localStorage.setItem('cm-view', cmView);
    };

    if (isCooking) {
        handleCookingMode({
            onload: 'true',
        });
        handleCMview({ onload: 'true' });
    }

    const handleCookingModeIngredients = () => {
        const toggleButton = document.getElementById('toggle-ingredients');
        const ingredientsDropDown = document.getElementById(
            'ingredients-wrapper'
        );
        ingredientsDropDown.classList.toggle('temp-show');
        toggleButton.classList.toggle('showing-list');
    };

    const handleCMTheme = () => {
        const preferredTheme = localStorage.getItem('cm-theme');

        switch (preferredTheme) {
            case 'zebra':
                localStorage.setItem('cm-theme', '');

                break;
            default:
                localStorage.setItem('cm-theme', 'zebra');
        }
        document.body.classList.toggle('zebra');
    };

    const disableAction = (e) => {
        if (!liveMode) {
            e.preventDefault();
        }
    };

    const viewingImageOverflow = (e) => {
        const parentEl = e.parentNode;
        parentEl.classList.toggle('large-view');
    };

    const recipeIsPrivate =
        recipe.visibility === 'private' ? 'mediabox-private-recipe' : null;

    const handleCopyClipboard = (e) => {
        const copyUrl = window.location.href;
        const buttonPressed = e.target;
        const inputToCopy = document.getElementById('clipboard-url');

        inputToCopy.value = copyUrl;

        inputToCopy.select();
        inputToCopy.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand('copy');

        buttonPressed.classList.toggle('show');
        setTimeout(() => {
            buttonPressed.classList.toggle('show');
        }, 1000);
    };

    const hasProfileImage = author.profileImage;

    const userMention =
        users &&
        recipe.specialMention &&
        typeof recipe.specialMention === 'string';

    const userMentionInfo = userMention
        ? users[recipe.specialMention]
        : userMention;
    // console.log(userMentionInfo);
    const userMentionProfileId =
        userMention && userMention.alias
            ? userMention.alias
            : recipe.specialMention;

    const customSpecialMention =
        users &&
        recipe.specialMention &&
        typeof recipe.specialMention === 'object';

    const customSpecialMentionEl = customSpecialMention && (
        <>
            <span>TODO:{recipe.specialMention.title}</span>
            <span>{recipe.specialMention.shortDescription}</span>
            <span>{recipe.specialMention.mentionThumbnail}</span>
        </>
    );

    return (
        <div className="recipe">
            <Row className="recipe-header">
                <Col l={12} m={12} s={12} xl={12}>
                    <h1 className="recipe-title temp-header">{recipe.title}</h1>
                    <div className="temp-hide">
                        {liveMode && !viewerIsAuthor && (
                            <Starring
                                recipeId={recipeId}
                                setBookMarks={setBookMarks}
                                isLoggedIn={isLoggedIn}
                            />
                        )}
                    </div>

                    <div className="temp-hide">
                        <p className="description">{recipe.description}</p>
                    </div>
                </Col>
            </Row>
            {liveMode ? (
                <div className="cooking-mode-controls">
                    <button
                        className="btn enter-cm-btn top temp-hide waves-effect waves-teal"
                        onClick={handleCookingMode}
                    >
                        <Icon>flash_on</Icon>
                        Start cooking
                    </button>
                    <button
                        className="btn exit-cm-btn temp-show waves-effect waves-teal"
                        onClick={handleCookingMode}
                    >
                        <Icon>done</Icon>
                        Finish cooking
                    </button>
                    <button
                        className="toggle-theme btn browser-default temp-show waves-effect waves-teal"
                        title="Change theme"
                        onClick={handleCMTheme}
                    >
                        <Icon>invert_colors</Icon>
                    </button>
                    <button
                        id="toggle-ingredients"
                        className="btn toggle-ingredients temp-show waves-effect waves-teal"
                        data-target="ingredients-wrapper"
                        onClick={handleCookingModeIngredients}
                    >
                        Ingredients
                        <Icon>arrow_drop_up</Icon>
                    </button>
                </div>
            ) : null}

            <Row>
                <Col
                    className={
                        'temp-hide thumbnail-frame push-m8 push-l8 push-xl8 ' +
                        recipeIsPrivate
                    }
                    s={12}
                    m={4}
                    l={4}
                    xl={4}
                >
                    <MediaBox
                        id="MediaBox_7"
                        className="thumbnail-image"
                        data-private="private"
                        options={{
                            inDuration: 275,
                            onCloseEnd: viewingImageOverflow,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: viewingImageOverflow,
                            outDuration: 200,
                        }}
                    >
                        <img
                            className="private-recipe"
                            src={thumbnail}
                            alt={recipe.title}
                        />
                    </MediaBox>
                </Col>

                <Col
                    className="temp-hide pull-m4 pull-l4 pull-xl4"
                    s={12}
                    m={8}
                    l={8}
                    xl={8}
                >
                    <Row>
                        <NavLink
                            className="recipe-profile-link"
                            to={'/' + profileId}
                            title={'Visit ' + author.displayName + "'s profile"}
                            onClick={disableAction}
                        >
                            <Col
                                s={6}
                                m={6}
                                l={6}
                                xl={6}
                                className="author-wrapper"
                            >
                                {/* <Col> */}
                                <div>
                                    <div className="author-thumbnail-frame">
                                        {hasProfileImage ? (
                                            <img
                                                className="profile-preview"
                                                src={author.profileThumbnailUrl}
                                                alt={
                                                    'Image of ' +
                                                    author.displayName
                                                }
                                            />
                                        ) : (
                                            <Icon className="no-avatar">
                                                person
                                            </Icon>
                                        )}
                                    </div>
                                </div>
                                {/* </Col> */}
                                <div className="author-block">
                                    <span className="author-name">
                                        {author.displayName}
                                    </span>
                                    <span className="author-title">
                                        {author.profileTitle
                                            ? author.profileTitle
                                            : 'New member'}
                                    </span>
                                </div>
                            </Col>
                        </NavLink>
                        {userMention && userMentionInfo && (
                            <Col
                                s={6}
                                m={6}
                                l={6}
                                xl={6}
                                className="right-align"
                            >
                                <NavLink
                                    className="recipe-profile-link user-mention-block"
                                    to={'/' + userMentionProfileId}
                                    title={
                                        'Visit ' +
                                        userMentionInfo.displayName +
                                        "'s profile"
                                    }
                                    onClick={disableAction}
                                >
                                    <div className="author-block mention-block">
                                        <span className="mention-title">
                                            Special mention
                                        </span>
                                        <span className="author-name mention-name">
                                            {userMentionInfo.displayName}
                                        </span>
                                    </div>
                                    {/* <Col> */}
                                    <div className="author-thumbnail-frame">
                                        {userMentionInfo.profileThumbnailUrl ? (
                                            <img
                                                className="profile-preview"
                                                src={
                                                    userMentionInfo.profileThumbnailUrl
                                                }
                                                alt={
                                                    'Image of ' +
                                                    userMentionInfo.displayName
                                                }
                                            />
                                        ) : (
                                            <Icon className="no-avatar">
                                                person
                                            </Icon>
                                        )}
                                    </div>
                                    {/* </Col> */}
                                </NavLink>
                            </Col>
                        )}
                    </Row>

                    <Row className="recipe-info-row">
                        <Col className="temp-hide" s={4} m={4} l={2} xl={2}>
                            <p className="servings-size-wrapper">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-users"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx="9" cy="7" r="4" />
                                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                </svg>
                                Serves {recipe.servings}
                            </p>
                        </Col>
                        <Col className="temp-hide" s={4} m={4} l={3} xl={3}>
                            <p className="total-time-wrapper flex-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-clock"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx="12" cy="12" r="9" />
                                    <polyline points="12 7 12 12 15 15" />
                                </svg>
                                <span className="total-time">
                                    {recipe.prepTime !== 0 ? (
                                        <span className="time-set">
                                            <span className="label">Prep:</span>
                                            <span className="time-text">
                                                {' ' +
                                                    ConvertToReadableTime(
                                                        recipe.prepTime
                                                    ).readableTime}
                                            </span>
                                        </span>
                                    ) : null}

                                    <span className="time-set">
                                        <span className="label">Cook:</span>
                                        <span className="time-text">
                                            {' ' +
                                                ConvertToReadableTime(
                                                    recipe.cookTime
                                                ).readableTime}
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </Col>

                        <Col className="temp-hide" s={4} m={4} l={3} xl={3}>
                            <p className="difficulty-wrapper">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-tool"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6-6a6 6 0 0 1 -8 -8l3.5 3.5" />
                                </svg>
                                <span className="sr-only">difficulty: </span>
                                <span className="strong">
                                    {recipe.difficulty}
                                </span>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        {recipe.tags && recipe.tags.length ? (
                            <Col s={9} m={9} l={9} xl={9}>
                                <ShowTags tags={recipe.tags} />
                            </Col>
                        ) : null}

                        <Col s={3} m={3} l={3} xl={3} className="right-align">
                            {liveMode && !recipeIsPrivate && (
                                <>
                                    <input
                                        id="clipboard-url"
                                        className="sr-only"
                                        type="text"
                                        defaultValue=""
                                    />
                                    <button
                                        className="btn copy-clipboard flex-center right"
                                        type="button"
                                        onClick={handleCopyClipboard}
                                        title="Copy to clipboard"
                                        value=""
                                    >
                                        Copy link
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-copy"
                                            width="44"
                                            height="44"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />
                                            <rect
                                                x="8"
                                                y="8"
                                                width="12"
                                                height="12"
                                                rx="2"
                                            />
                                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                        </svg>
                                        <span className="copied-text">
                                            Copied!
                                        </span>
                                    </button>
                                </>
                            )}
                        </Col>

                        <Col s={12} m={12} l={12} xl={12}>
                            {customSpecialMention && customSpecialMentionEl}
                        </Col>
                    </Row>
                </Col>

                <Col
                    className="full-details temp-hide"
                    s={12}
                    m={12}
                    l={12}
                    xl={12}
                >
                    <Collapsible accordion={false}>
                        <CollapsibleItem
                            expanded={false}
                            header="Show more"
                            icon={<Icon>view_headline</Icon>}
                            node="div"
                        >
                            {recipe.fullDetails.split('\n').map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}
                        </CollapsibleItem>
                    </Collapsible>
                </Col>
            </Row>

            <Row>
                <Col id="ingredients-container" s={12} m={4} l={4} xl={4}>
                    <div
                        id="ingredients-wrapper"
                        className="ingredients temp-toggle"
                    >
                        <h2 className="recipe-section-heading temp-hide">
                            Ingredients
                        </h2>
                        <p className="checklist-helper">
                            (click once if have it, twice when used)
                        </p>

                        <IngredientsListLayout
                            ingredients={ingredients}
                            checklistEvent={updateCookie}
                            cookingModeState={ingredientsState}
                            isCooking={isCooking}
                        />
                        <div
                            className="cm-ingredients-overlay"
                            onClick={handleCookingModeIngredients}
                        ></div>
                    </div>
                </Col>

                <Col className="steps temp-large" s={12} m={8} l={8} xl={8}>
                    <div className="steps-cm-wrapper">
                        <h2 className="recipe-section-heading temp-hide">
                            Steps
                        </h2>
                        {liveMode ? (
                            <button
                                className="btn enter-cm-btn middle temp-hide waves-effect waves-teal"
                                onClick={handleCookingMode}
                            >
                                <Icon>flash_on</Icon>
                                Start cooking
                            </button>
                        ) : null}
                    </div>

                    <StepsListLayout steps={steps} liveMode={liveMode} />

                    {liveMode ? (
                        <Row className="bottom-cm-wrapper">
                            <button
                                className="btn enter-cm-btn bottom temp-hide waves-effect waves-green"
                                onClick={handleCookingMode}
                            >
                                <Icon>flash_on</Icon>
                                Start cooking
                            </button>
                            <button
                                className="btn step-view cm-view temp-show waves-effect waves-teal"
                                type="button"
                                onClick={handleCMview}
                                value="step"
                                title="Go to step view"
                            >
                                <Icon>view_carousel</Icon>
                                Step view
                            </button>
                            <button
                                className="btn list-view cm-view temp-show waves-effect waves-teal"
                                type="button"
                                onClick={handleCMview}
                                title="Go to list view"
                                value="list"
                            >
                                <Icon>view_list</Icon>
                                List view
                            </button>
                        </Row>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
};

export default RecipeLayout;
