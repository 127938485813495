import { Link } from 'react-router-dom';

const FooterLinks = () => {
    return (
        <ul className="footer-links temp-hide">
            <li>
                <Link className="" to="/privacy" title="Privacy policy">
                    Privacy policy
                </Link>
            </li>
            <li>
                <Link className="" to="/termsofuse" title="Terms of use">
                    Terms of use
                </Link>
            </li>
        </ul>
    );
};

export default FooterLinks;
