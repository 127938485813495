import { useState, useContext } from 'react';
import { Row, Col, TextInput } from 'react-materialize';
import { IngredientContext } from '../../../store/contexts/ingredientContext';

const AddIngredient = () => {
    const { ingredients, dispatch } = useContext(IngredientContext);
    const [ingredient, setIngredient] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (ingredient) {
            dispatch({ type: 'ADD_INGREDIENT', ingredient });
            setIngredient('');
        }
    };

    const handleOnKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            return handleSubmit(e);
        }
    };

    const handleOnChange = (e) => {
        setIngredient(e.target.value);
    };

    return (
        <Row className="ingredient-wrapper">
            <TextInput
                id="ingredients"
                className="ingredient-adder tooltipped"
                type="text"
                autoComplete="off"
                placeholder={
                    ingredients.length
                        ? 'Add another ingredient'
                        : 'Write a ingredient'
                }
                onKeyPress={handleOnKeyPress}
                onChange={handleOnChange}
                data-tooltip="Include the measurement too"
                value={ingredient}
                s={12}
                m={9}
                l={9}
                xl={9}
            />

            <Col s={12} m={3} l={3} xl={3} className="item-controls">
                <button
                    className="btn item-add-btn waves-effect waves-light"
                    type="button"
                    onClick={handleSubmit}
                >
                    Add
                </button>
            </Col>
        </Row>
    );
};

export default AddIngredient;
