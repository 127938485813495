export const cookingTimersReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TIMER':
            let newCookingTimerState = { ...state };
            newCookingTimerState[action.index] = action.value;
            return newCookingTimerState;

        case 'REMOVE_ENTIRE_TIMER':
            return {};
        default:
    }
};
