import { useContext, useState, useRef, useEffect } from 'react';
import ListBookMark from './listBookMark';
import { Col, Icon, Modal, Button } from 'react-materialize';
import './index.scss';
import { BookMarksContext } from '../../../store/contexts/bookMarkContext';
import DeleteListModal from './deleteListModal';
import AddNewList from './addNewList';

const BookMarkList = (props) => {
    const { recipes, updateBookMarks } = props;
    const { bookMarks, setBookMarks } = useContext(BookMarksContext);
    const [deleteListModal, setDeleteListModal] = useState(false);
    const [editListModal, setEditListModal] = useState(false);
    const pageWrapper = document.querySelector('.page-wrapper');

    const onMount = useRef(true);

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            updateBookMarks(bookMarks);
        }
    }, [bookMarks, updateBookMarks]);

    const allBookMarkLists = Object.keys(bookMarks).length;

    // All bookmarks
    const allLists =
        allBookMarkLists &&
        bookMarks.starred &&
        bookMarks.starred.map((recipeId, i) => {
            const individualRecipeId = recipeId;

            return (
                <ListBookMark
                    bookMarkedRecipe={recipes[recipeId]}
                    recipeId={individualRecipeId}
                    key={i}
                />
            );
        });

    const toggleSettingsPanel = (e) => {
        const settingsBlock = e.target.dataset.targetListblock;
        document
            .querySelector('.list-controls-' + settingsBlock)
            .classList.toggle('show');
    };

    const toggleDeleteModal = (e) => {
        const listBlock = e.target && e.target.dataset.targetListblock;
        setDeleteListModal(listBlock);
    };

    const toggleDeleteModalClose = () => {
        setDeleteListModal(null);
    };

    const toggleEditModal = (e) => {
        const listBlock = e.target && e.target.dataset.targetListblock;
        setEditListModal(listBlock);
    };

    const toggleEditModalClose = () => {
        setEditListModal(null);
        document.body.style.overflow = '';
    };

    const handleEditSubmit = (newList) => {
        let newLibraryLists = [...bookMarks.libraryLists];
        newLibraryLists[editListModal] = newList;
        const newBookMarks = { ...bookMarks, libraryLists: newLibraryLists };
        setBookMarks(newBookMarks);
        setEditListModal(null);
    };

    const removeList = (newList) => {
        setBookMarks(newList);
        setDeleteListModal(null);
    };

    // Library lists
    const libraryLists =
        bookMarks.libraryLists &&
        bookMarks.libraryLists.map((listObject, i) => {
            const individualList = listObject;
            const title = individualList.title;

            const singleList = individualList.recipes.map((recipeId, i) => {
                const individualRecipeId = recipeId;

                return (
                    <ListBookMark
                        bookMarkedRecipe={recipes[recipeId]}
                        recipeId={individualRecipeId}
                        key={i}
                    />
                );
            });
            return (
                <Col
                    className="list-block-wrapper"
                    s={12}
                    m={4}
                    l={3}
                    xl={3}
                    key={i}
                >
                    <div className="list-block">
                        <h3 className="list-title">{title}</h3>
                        <div className="list-settings">
                            <button
                                className="btn browser-default clean list-settings-toggle"
                                data-target-listblock={i}
                                onClick={toggleSettingsPanel}
                            >
                                {/* <Icon>settings</Icon> */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-adjustments"
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#828282"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx="6" cy="10" r="2" />
                                    <line x1="6" y1="4" x2="6" y2="8" />
                                    <line x1="6" y1="12" x2="6" y2="20" />
                                    <circle cx="12" cy="16" r="2" />
                                    <line x1="12" y1="4" x2="12" y2="14" />
                                    <line x1="12" y1="18" x2="12" y2="20" />
                                    <circle cx="18" cy="7" r="2" />
                                    <line x1="18" y1="4" x2="18" y2="5" />
                                    <line x1="18" y1="9" x2="18" y2="20" />
                                </svg>
                            </button>
                            <div className={'list-controls list-controls-' + i}>
                                <button
                                    className="btn browser-default clean"
                                    onClick={toggleEditModal}
                                    data-target-listblock={i}
                                >
                                    Edit
                                </button>
                                <button
                                    className="btn browser-default clean"
                                    onClick={toggleDeleteModal}
                                    data-target-listblock={i}
                                >
                                    Delete
                                </button>

                                <div
                                    className="overlay"
                                    onClick={toggleSettingsPanel}
                                    data-target-listblock={i}
                                ></div>
                            </div>
                        </div>
                        <ul className="list-block-list" data-listblock={i}>
                            {singleList}
                        </ul>
                    </div>
                </Col>
            );
        });

    return (
        <>
            {allBookMarkLists ? (
                <Col
                    className="list-block-wrapper all-list-block"
                    s={12}
                    m={4}
                    l={3}
                    xl={3}
                >
                    <div className="list-block">
                        <h3 className="list-title all-list-title">All</h3>
                        <ul className="list-block-list">{allLists}</ul>
                    </div>
                </Col>
            ) : null}
            {libraryLists ? libraryLists : null}
            {deleteListModal && (
                <DeleteListModal
                    listNumber={deleteListModal}
                    setBookMarks={setBookMarks}
                    action={removeList}
                    toggleDeleteModalClose={toggleDeleteModalClose}
                />
            )}

            {editListModal && (
                <Modal
                    actions={[
                        <Button
                            className="flex-center browser-default right"
                            modal="close"
                            node="button"
                        >
                            <Icon>close</Icon>
                        </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="Edit list"
                    id="edit-list"
                    className="create-list-modal"
                    open={true}
                    options={{
                        dismissible: true,
                        endingTop: '10%',
                        inDuration: 250,
                        onCloseEnd: null,
                        onCloseStart: toggleEditModalClose,
                        onOpenEnd: null,
                        onOpenStart: null,
                        opacity: 0.5,
                        outDuration: 250,
                        preventScrolling: true,
                        startingTop: '4%',
                    }}
                    root={pageWrapper}
                >
                    <AddNewList
                        recipes={recipes}
                        title={bookMarks.libraryLists[editListModal].title}
                        listRecipes={
                            bookMarks.libraryLists[editListModal].recipes
                        }
                        handleSubmit={handleEditSubmit}
                    />
                </Modal>
            )}
        </>
    );
};

export default BookMarkList;
