import authReducer from './authReducer';
import recipeReducer from './recipeReducer';
import uploadImageReducer from './uploadImageReducer';
import adminReducer from './adminReducer';
import starringReducer from './starringReducer';
import profileReducer from './profileReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
    auth: authReducer,
    recipe: recipeReducer,
    admin: adminReducer,
    starring: starringReducer,
    profile: profileReducer,
    uploadImage: uploadImageReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});

export default rootReducer;
