// import { useState } from 'react';
import moment from 'moment';

const UserNotifications = (props) => {
    const { userNotifications, action } = props;
    // const [previewUser, setPreviewUser] = useState();

    const handleClick = (e) => {
        e.preventDefault();
        // const userId = e.target.dataset.userid;
        // setPreviewUser(userId);
    };

    // const handlePreview = previewUser;

    const printNotifications = userNotifications
        ? Object.keys(userNotifications).map((notification, i) => {
              const individualNotification = userNotifications[notification];
              const NotificationIsValid =
                  individualNotification && Object.keys(individualNotification)
                      ? Object.keys(individualNotification)
                      : null;

              const template = NotificationIsValid ? (
                  <li key={i}>
                      <a
                          className="notification-link"
                          href={'/' + individualNotification.userId}
                          onClick={handleClick}
                          data-userid={individualNotification.userId}
                      >
                          <span className="notification-title notification-field">
                              {individualNotification.title}
                          </span>{' '}
                          <span className="notification-time">
                              {moment
                                  .unix(
                                      individualNotification.timeCreated.seconds
                                  )
                                  .format('DD/MM/YYYY hh:mma')}
                          </span>
                      </a>
                      <button
                          type="button"
                          className="btn waves-effect waves-light"
                          onClick={(e) => {
                              e.preventDefault();
                              action(
                                  notification,
                                  individualNotification,
                                  'userNotifications'
                              );
                          }}
                      >
                          Approve
                      </button>
                      <br />
                  </li>
              ) : null;

              return template;
          })
        : null;

    if (userNotifications) {
        return <ul className="user notifications">{printNotifications}</ul>;
    } else {
        return <p>Nothing yet</p>;
    }
};

export default UserNotifications;
