import { createContext, useReducer } from 'react';
import { ingredientReducer } from '../reducers/ingredientsReducer';

export const IngredientContext = createContext();

const IngredientContextProvider = (props) => {
    const [ingredients, dispatch] = useReducer(ingredientReducer, [], () => {
        return props.ingredients;
    });

    return (
        <IngredientContext.Provider value={{ ingredients, dispatch }}>
            {props.children}
        </IngredientContext.Provider>
    );
};

export default IngredientContextProvider;
