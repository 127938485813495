import { Component } from 'react';
import RecipeLayout from '../recipes/recipeLayout';
import { ScrollSpy, Tooltip, Modal, Pushpin } from 'materialize-css';
import {
    Row,
    Col,
    Textarea,
    TextInput,
    Select,
    Icon,
    Checkbox,
} from 'react-materialize';
import debounce from 'lodash/debounce';
import RangeTimer from '../makerecipe/rangeTimer';

// Styles
import './index.scss';

// New
import IngredientContextProvider from '../../../store/contexts/ingredientContext';
import EditingIngredientsList from '../ingredients/editingIngredientsList';
import AddIngredient from '../makerecipe/addIngredient';

import StepConTextProvider from '../../../store/contexts/stepContext';
import EditStepsList from '../steps/editingStepsList';

import CookingTimersContextProvider from '../../../store/contexts/cookingTimersContext';

import ImageUploader, { NewImageUploader } from './imageUploader';

// Checklist
import Checklist from './checklist';
import { FormRequirements } from '../forms/formRequirements';
import Copyright from '../recipes/copyright';

import AddingTags from './addingTags';
import SpecialMentions from './specialMentions';
import recipeTags from './recipeTags';
import Origin from './origin';

class RecipeForm extends Component {
    formUiFields = {
        missingFields: false,
        creatingRecipe: false,
        editorReady: true,
    };

    requiredFields = [
        'title',
        'description',
        'fullDetails',
        'thumbnailImage',
        'ingredients',
        'steps',
        'agreetc',
    ];

    // Adjust state for editing
    adjustRecipeToEdit = () => {
        const fieldAdditions = { ...this.formUiFields };
        const recipeEditingState = { ...fieldAdditions, ...this.props.recipe };
        return recipeEditingState;
    };

    recipeCleaner = (recipe) => {
        const cleanRecipeState = { ...recipe };
        const formUifields = this.formUiFields;
        Object.keys(recipe).forEach((field) => {
            if (field in formUifields) {
                delete cleanRecipeState[field];
            }
        });

        return cleanRecipeState;
    };

    state = this.adjustRecipeToEdit();

    componentDidMount() {
        // Tooltip
        const elems = document.querySelectorAll('.tooltipped');
        const options = { position: 'top' };
        Tooltip.init(elems, options);

        // pushpin
        const pushPinElems = document.querySelector('.pushpin');
        const pushPinsWidth = pushPinElems.offsetWidth;
        const targetEl = document.getElementById(
            pushPinElems.dataset.pushpintarget
        );

        const pushPinsOptions = {
            top: targetEl.offsetTop,
            bottom:
                targetEl.offsetTop +
                targetEl.offsetHeight -
                pushPinElems.offsetHeight,
            onPositionChange: (e) => {
                if (e === 'pinned') {
                    pushPinElems.style.width = pushPinsWidth + 'px';
                } else {
                    pushPinElems.style.width = '';
                }
            },
        };

        Pushpin.init(pushPinElems, pushPinsOptions);

        // scrollspy
        const scrollSpyElems = document.querySelectorAll('.scrollspy');
        const scrollSpyOptions = {};
        ScrollSpy.init(scrollSpyElems, scrollSpyOptions);

        // Modals
        const modelElems = document.querySelectorAll('.modal');
        const modalOptions = { opacity: 0.5 };
        Modal.init(modelElems, modalOptions);
    }

    componentWillUnmount() {
        // Tooltip
        const toolTipElems = document.querySelectorAll('.tooltipped');
        if (toolTipElems.length) {
            toolTipElems.forEach((elem) => {
                const tooltip = Tooltip.getInstance(elem);
                tooltip.destroy();
            });
        }
        // Scroll spy
        const scrollSpyElems = document.querySelectorAll('.scrollspy');
        if (scrollSpyElems.length) {
            scrollSpyElems.forEach((elem) => {
                const scrollSpy = ScrollSpy.getInstance(elem);
                scrollSpy.destroy();
            });
        }

        // Modals
        const modalElems = document.querySelectorAll('.modal');
        if (modalElems.length) {
            modalElems.forEach((elem) => {
                const modal = Modal.getInstance(elem);
                modal.destroy();
            });
        }
    }

    handleNameChange = (e) => {
        const textName = e.target.name;
        let textValue = e.target.value;

        if (e.target.type === 'checkbox') {
            textValue = e.target.checked;
        }

        this.setStateValue(textName, textValue);
    };

    setStateValue = debounce((name, value) => {
        this.setState({
            [name]: value,
        });
    }, 500);

    setPrepTimeStateValue = debounce((value) => {
        this.setState({
            prepTime: value,
        });
    }, 500);

    setCookTimeStateValue = debounce((value) => {
        this.setState({
            cookTime: value,
        });
    }, 500);

    setStateNow = (name, value) => {
        this.setStateValue(name, value);
    };

    handleOnChangeFile = (file, imageProp) => {
        this.setState({
            [imageProp]: file,
        });
    };

    handlePreview = () => {
        const previewBox = document.querySelector('.preview');
        const bodyEl = document.body;
        previewBox.classList.toggle('show');
        bodyEl.classList.toggle('disable-scroll');
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const currentState = this.state;
        const creatingRecipeInProgress = currentState.creatingRecipe;
        const recipe = this.recipeCleaner(currentState);
        const formErrors = FormRequirements(recipe, this.requiredFields);
        const recipeIsInvalid = Object.keys(formErrors).length;

        if (recipeIsInvalid) {
            this.setState({
                missingFields: true,
            });
        }

        if (!recipeIsInvalid && !creatingRecipeInProgress) {
            this.setState({
                missingFields: false,
                creatingRecipe: true,
            });

            // Send recipe to action
            this.props.action(recipe);
            // console.log(recipe);
        }
    };

    render() {
        const { author, users } = this.props;

        const editorReady = this.state.editorReady;

        return (
            <IngredientContextProvider ingredients={this.state.ingredients}>
                <StepConTextProvider steps={this.state.steps}>
                    <CookingTimersContextProvider>
                        <div className="make-recipe">
                            <Row>
                                <form
                                    id="recipe-builder"
                                    className="recipe-builder col s12 m9 l9 xl9"
                                    onSubmit={this.handleStepSubmit}
                                >
                                    <div id="general" className="scrollspy">
                                        <Row>
                                            <Col s={12} m={12} l={12} xl={12}>
                                                <h2 className="list-heading">
                                                    General details
                                                </h2>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <TextInput
                                                id="recipe-name"
                                                className="validate"
                                                label="Recipe name"
                                                type="text"
                                                name="title"
                                                autoComplete="off"
                                                onChange={this.handleNameChange}
                                                required
                                                data-length={30}
                                                maxLength="30"
                                                defaultValue={this.state.title}
                                                s={12}
                                                m={6}
                                                l={6}
                                                xl={6}
                                            />

                                            <Select
                                                id="servings"
                                                name="servings"
                                                onChange={this.handleNameChange}
                                                multiple={false}
                                                label="# of servings"
                                                s={4}
                                                m={3}
                                                l={2}
                                                xl={2}
                                                options={{
                                                    classes: '',
                                                    dropdownOptions: {
                                                        alignment: 'left',
                                                        autoTrigger: true,
                                                        closeOnClick: true,
                                                        constrainWidth: true,
                                                        coverTrigger: true,
                                                        hover: false,
                                                        inDuration: 150,
                                                        onCloseEnd: null,
                                                        onCloseStart: null,
                                                        onOpenEnd: null,
                                                        onOpenStart: null,
                                                        outDuration: 250,
                                                    },
                                                }}
                                                defaultValue={
                                                    this.state.servings
                                                }
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10+">10+</option>
                                            </Select>

                                            <Select
                                                id="difficulty"
                                                name="difficulty"
                                                onChange={this.handleNameChange}
                                                multiple={false}
                                                label="Difficulty"
                                                s={4}
                                                m={3}
                                                l={2}
                                                xl={2}
                                                options={{
                                                    classes: '',
                                                    dropdownOptions: {
                                                        alignment: 'left',
                                                        autoTrigger: true,
                                                        closeOnClick: true,
                                                        constrainWidth: true,
                                                        coverTrigger: true,
                                                        hover: false,
                                                        inDuration: 150,
                                                        onCloseEnd: null,
                                                        onCloseStart: null,
                                                        onOpenEnd: null,
                                                        onOpenStart: null,
                                                        outDuration: 250,
                                                    },
                                                }}
                                                defaultValue={
                                                    this.state.difficulty
                                                }
                                            >
                                                <option value="easy">
                                                    easy
                                                </option>
                                                <option value="moderate">
                                                    moderate
                                                </option>
                                                <option value="challenge">
                                                    challenge
                                                </option>
                                            </Select>

                                            <Select
                                                id="visibility"
                                                name="visibility"
                                                onChange={this.handleNameChange}
                                                multiple={false}
                                                label="Public recipe?"
                                                s={4}
                                                m={3}
                                                l={2}
                                                xl={2}
                                                options={{
                                                    classes: '',
                                                    dropdownOptions: {
                                                        alignment: 'left',
                                                        autoTrigger: true,
                                                        closeOnClick: true,
                                                        constrainWidth: true,
                                                        coverTrigger: true,
                                                        hover: false,
                                                        inDuration: 150,
                                                        onCloseEnd: null,
                                                        onCloseStart: null,
                                                        onOpenEnd: null,
                                                        onOpenStart: null,
                                                        outDuration: 250,
                                                    },
                                                }}
                                                defaultValue={
                                                    this.state.visibility
                                                }
                                            >
                                                <option value="public">
                                                    public
                                                </option>
                                                <option value="private">
                                                    private
                                                </option>
                                            </Select>
                                        </Row>

                                        <Row>
                                            <TextInput
                                                id="description"
                                                className="tooltipped validate"
                                                data-tooltip="Write a short teaser sentence"
                                                label="Description/teaser"
                                                name="description"
                                                type="text"
                                                autoComplete="off"
                                                required
                                                onChange={this.handleNameChange}
                                                data-length={90}
                                                maxLength="90"
                                                defaultValue={
                                                    this.state.description
                                                }
                                                s={12}
                                                m={12}
                                                l={12}
                                                xl={12}
                                            />
                                        </Row>

                                        <Row>
                                            <Textarea
                                                id="fullDetails"
                                                className="full-details-textarea tooltipped validate"
                                                data-tooltip="Multiple lines are allowed. This field will be hidden/tiny by default."
                                                name="fullDetails"
                                                label="Full details"
                                                autoComplete="off"
                                                onChange={this.handleNameChange}
                                                required
                                                success="Please note: There are certain laws around recipe copyright. We can help you! See our recipe copyright guide below."
                                                defaultValue={
                                                    this.state.fullDetails
                                                }
                                                s={12}
                                                m={12}
                                                l={12}
                                                xl={12}
                                            />
                                        </Row>

                                        <Copyright />

                                        <Row className="row-padding">
                                            <ImageUploader
                                                imageProp="thumbnailImage"
                                                action={this.handleOnChangeFile}
                                                thumbnailState={
                                                    this.state.thumbnailImage
                                                }
                                            />
                                            <NewImageUploader
                                                imageProp="thumbnailImage"
                                                action={this.handleOnChangeFile}
                                                thumbnailState={
                                                    this.state.thumbnailImage
                                                }
                                            />

                                            <Col s={6} m={6} l={6} xl={6}>
                                                <RangeTimer
                                                    sliderId="prepTimeText"
                                                    labelText="Prep time:"
                                                    minimumValue="0"
                                                    defaultStartValue={
                                                        this.state.prepTime
                                                            ? this.state
                                                                  .prepTime
                                                            : '30'
                                                    }
                                                    optionalClasses="prep-time slider-one"
                                                    timerNumber="one"
                                                    action={
                                                        this
                                                            .setPrepTimeStateValue
                                                    }
                                                />

                                                <RangeTimer
                                                    sliderId="cookTimeText"
                                                    labelText="Cook time:"
                                                    minimumValue="0"
                                                    defaultStartValue={
                                                        this.state.cookTime
                                                            ? this.state
                                                                  .cookTime
                                                            : '90'
                                                    }
                                                    optionalClasses="cook-time slider-two"
                                                    timerNumber="two"
                                                    action={
                                                        this
                                                            .setCookTimeStateValue
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <Col s={12} m={12} l={12} xl={12}>
                                        <h2 className="list-heading">Tags</h2>
                                    </Col>

                                    <AddingTags
                                        updateTags={this.setStateNow}
                                        currentTags={this.state.tags}
                                        suggestedTags={recipeTags}
                                        limit={5}
                                    />

                                    <Origin
                                        callBack={this.setStateNow}
                                        currentCountry={this.state.countryName}
                                    />

                                    <Row>
                                        <Col
                                            id="ingredients-container"
                                            className="ingredients-container scrollspy"
                                            s={12}
                                            m={12}
                                            l={12}
                                            xl={12}
                                        >
                                            <h2 className="list-heading">
                                                Ingredients
                                            </h2>

                                            <EditingIngredientsList
                                                updateIngredients={
                                                    this.setStateNow
                                                }
                                            />
                                            <AddIngredient />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col
                                            id="steps-container"
                                            className="steps-container scrollspy"
                                            s={12}
                                            m={12}
                                            l={12}
                                            xl={12}
                                        >
                                            <h2 className="list-heading">
                                                Steps
                                            </h2>

                                            <EditStepsList
                                                updateSteps={this.setStateNow}
                                            />
                                            {/* <AddStep /> */}
                                        </Col>
                                    </Row>

                                    {/* <Row>
                            <Col
                                id="hints"
                                className="hints-container box-container warm-date scrollspy"
                                s={12}
                                m={12}
                                l={12}
                                xl={12}
                            >
                                <h2 className="list-heading">Hints</h2>
                            </Col>
                        </Row> */}

                                    <Row>
                                        <Col
                                            className="special-mentions-container"
                                            s={12}
                                            m={12}
                                            l={12}
                                            xl={12}
                                        >
                                            <h2 className="list-heading">
                                                Special mentions
                                            </h2>
                                            <div className="special-mentions-wrapper">
                                                <Col
                                                    s={12}
                                                    m={12}
                                                    l={12}
                                                    xl={12}
                                                >
                                                    {/* <p>
                                                        This is a where you can
                                                        mention
                                                        someone/something
                                                        special or share the
                                                        glory, whether if they
                                                        have an account or not.
                                                    </p> */}
                                                    <p>
                                                        If you want to mention
                                                        someone and share the
                                                        glory, do that here.
                                                    </p>
                                                </Col>

                                                {users && (
                                                    <SpecialMentions
                                                        users={users}
                                                        specialMention={
                                                            this.state
                                                                .specialMention
                                                        }
                                                        action={
                                                            this.setStateNow
                                                        }
                                                        currentUser={
                                                            this.props
                                                                .currentUser
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col
                                            id="agreetc-container"
                                            className="scrollspy"
                                            s={12}
                                            m={12}
                                            l={12}
                                            xl={12}
                                        >
                                            <Checkbox
                                                type="checkbox"
                                                id="agreetc"
                                                className="agreetc"
                                                name="agreetc"
                                                label={
                                                    'I agree with the terms and conditions on the terms of use page. I understand all content added is of my own or have permission and will be reviewed to ensure content is appropriate regardless of being private.'
                                                }
                                                checked={
                                                    this.state.agreetc
                                                        ? true
                                                        : false
                                                }
                                                value="yes"
                                                onChange={this.handleNameChange}
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                    <Row>
                                        <Col
                                            s={6}
                                            m={6}
                                            l={6}
                                            xl={6}
                                            className="center-align"
                                        >
                                            <button
                                                type="button"
                                                className="btn recipe-action-btn"
                                                onClick={this.handlePreview}
                                            >
                                                <Icon>find_in_page</Icon>
                                                <span className="recipe-action-btn-text">
                                                    Preview recipe
                                                </span>
                                            </button>
                                        </Col>
                                        <Col
                                            s={6}
                                            m={6}
                                            l={6}
                                            xl={6}
                                            className="center-align"
                                        >
                                            <button
                                                type="submit"
                                                className="btn recipe-action-btn submit-btn waves-effect waves-light"
                                                onClick={this.handleSubmit}
                                                disabled={
                                                    this.state.creatingRecipe
                                                        ? 'disabled'
                                                        : null
                                                }
                                            >
                                                <Icon>create</Icon>
                                                <span className="recipe-action-btn-text">
                                                    {this.props.createRecipe
                                                        ? 'Add recipe'
                                                        : 'Update recipe'}
                                                </span>
                                            </button>
                                        </Col>
                                        {this.state.missingFields ? (
                                            <Col
                                                s={12}
                                                m={12}
                                                l={12}
                                                xl={12}
                                                className="center-align"
                                            >
                                                <p>
                                                    You missed something.{' '}
                                                    <span className="hide-on-small-only">
                                                        Use the checklist as a
                                                        guide.
                                                    </span>
                                                </p>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </form>

                                <Col s={0} m={3} l={3} xl={3}>
                                    <Row>
                                        <div
                                            className="table-of-contents floating-box pushpin"
                                            data-pushpintarget="recipe-builder"
                                        >
                                            <div className="table-of-contents-shadow"></div>
                                            <h3 className="checklist-title">
                                                Checklist
                                            </h3>

                                            {editorReady && (
                                                <Checklist
                                                    recipe={this.state}
                                                    requiredFields={
                                                        this.requiredFields
                                                    }
                                                />
                                            )}

                                            <button
                                                type="button"
                                                className="btn recipe-action-btn"
                                                onClick={this.handlePreview}
                                            >
                                                <Icon>find_in_page</Icon>
                                                <span className="recipe-action-btn-text">
                                                    Preview recipe
                                                </span>
                                            </button>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>

                            <div className="preview">
                                <h2 className="preview-title sr-only">
                                    <span>Recipe preview</span>
                                </h2>
                                <button
                                    className="exit-preview flex-center waves-effect waves-light"
                                    onClick={this.handlePreview}
                                >
                                    <Icon>close</Icon> Exit preview
                                </button>

                                <RecipeLayout
                                    recipe={this.state}
                                    author={author}
                                    users={users}
                                />
                            </div>
                        </div>
                    </CookingTimersContextProvider>
                </StepConTextProvider>
            </IngredientContextProvider>
        );
    }
}

export default RecipeForm;
