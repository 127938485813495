import { useState } from 'react';
import { Icon } from 'react-materialize';
import moment from 'moment';
import RecipeLayout from '../recipes/recipeLayout';
import IngredientContextProvider from '../../../store/contexts/ingredientContext';
import StepContextProvider from '../../../store/contexts/stepContext';
import CookingTimersContextProvider from '../../../store/contexts/cookingTimersContext';

const RecipeNotifications = (props) => {
    const { recipeNotifications, action, recipes, users } = props;
    const [previewRecipe, setPreviewRecipe] = useState();

    const handlePreview = (e) => {
        const previewBox = document.querySelector('.preview');
        // const floatingBox = document.querySelector('.floating-box');
        // const recipeBuilder = document.querySelector('.recipe-builder');
        const bodyEl = document.body;

        previewBox.classList.toggle('show');
        // floatingBox.classList.toggle('visually-hide');
        // recipeBuilder.classList.toggle('visually-hide');

        bodyEl.classList.toggle('disable-scroll');
    };

    const handleClick = (e) => {
        e.preventDefault();
        const recipeId = e.target.dataset.recipeid;
        setPreviewRecipe(recipeId);
        handlePreview();
    };

    const recipePreview = previewRecipe && (
        <IngredientContextProvider
            ingredients={recipes[previewRecipe].ingredients}
        >
            <StepContextProvider steps={recipes[previewRecipe].steps}>
                <CookingTimersContextProvider>
                    <RecipeLayout
                        recipe={recipes[previewRecipe]}
                        author={users[recipes[previewRecipe].authorId]}
                        users={users}
                    />
                </CookingTimersContextProvider>
            </StepContextProvider>
        </IngredientContextProvider>
    );

    const printNotifications = recipeNotifications
        ? Object.keys(recipeNotifications).map((notification, i) => {
              const individualNotification = recipeNotifications[notification];
              const NotificationIsValid =
                  individualNotification && Object.keys(individualNotification)
                      ? Object.keys(individualNotification)
                      : null;

              const template = NotificationIsValid ? (
                  <li key={i}>
                      <a
                          className="notification-link"
                          href={'/recipe/' + individualNotification.recipeId}
                          onClick={handleClick}
                          data-recipeid={individualNotification.recipeId}
                          data-authorid={individualNotification.authorId}
                      >
                          <span className="notification-title notification-field">
                              {individualNotification.title}
                          </span>{' '}
                          <span className="notification-time">
                              {moment
                                  .unix(
                                      individualNotification.timeCreated.seconds
                                  )
                                  .format('DD/MM/YYYY hh:mma')}
                          </span>
                      </a>
                      <button
                          type="button"
                          className="btn waves-effect waves-light"
                          onClick={(e) => {
                              e.preventDefault();
                              action(
                                  notification,
                                  individualNotification,
                                  'recipeNotifications'
                              );
                          }}
                      >
                          Approve
                      </button>
                      <br />
                  </li>
              ) : null;

              return template;
          })
        : null;

    if (recipeNotifications) {
        return (
            <>
                <ul className="recipe notifications">{printNotifications}</ul>

                <div className="preview">
                    <h2 className="preview-title sr-only">
                        <span>Recipe preview</span>
                    </h2>
                    <button
                        className="exit-preview flex-center waves-effect waves-light"
                        onClick={handlePreview}
                    >
                        <Icon>close</Icon> Exit preview
                    </button>

                    {recipePreview}
                </div>
            </>
        );
    } else {
        return <p>Nothing yet</p>;
    }
};

export default RecipeNotifications;
