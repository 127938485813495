import { useContext, useEffect, useRef, useState } from 'react';
import { StepContext } from '../../../store/contexts/stepContext';
import { Icon } from 'react-materialize';
import AddEditStep from '../makerecipe/addEditStep';
import ReactHtmlParser from 'react-html-parser';

const EditingListSteps = (props) => {
    const { steps, dispatch } = useContext(StepContext);
    const [addStep, setAddStep] = useState(false);
    const [editStep, setEditStep] = useState(false);
    const [editStepIndex, setEditStepIndex] = useState();
    const [editStepContent, setEditStepContent] = useState();
    const onMount = useRef(true);
    const updateSteps = props.updateSteps;

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            updateSteps('steps', steps);
        }
    }, [steps, updateSteps]);

    const handleEditStep = (e) => {
        e.preventDefault();
        const stepIndex = e.target.dataset.index;
        setEditStepIndex(stepIndex);
        setEditStepContent(steps[stepIndex]);
        setEditStep(!editStep);
    };

    const handleRemoveStep = (e) => {
        e.preventDefault();
        const stepIndex = e.target.dataset.index;
        dispatch({ type: 'REMOVE_STEP', index: stepIndex });
    };

    const cancelEditStepCallBack = () => {
        setEditStep(false);
    };

    const addStepToggle = () => {
        setAddStep(!addStep);
    };

    const addNewStepButton = addStep ? (
        <AddEditStep
            heading="Add step"
            cancelCallback={addStepToggle}
            content=""
        />
    ) : (
        <button className="btn add-step" type="button" onClick={addStepToggle}>
            Add step
        </button>
    );

    const allSteps = steps.length ? (
        <div className="list-steps-wrapper">
            <ol className="list-steps adding-items">
                {steps.map((step, i) => {
                    // Check if just text or complex step
                    const stepIsComplex = typeof step === 'object';
                    const stepContent = stepIsComplex ? step.content : step;
                    const stepHasTimer = stepIsComplex && 'time' in step;
                    return (
                        <li className="list-item" key={i}>
                            {ReactHtmlParser(stepContent)}
                            {stepHasTimer && (
                                <span
                                    className="step-has-timer"
                                    title="Timer step"
                                >
                                    <Icon className="timer-icon">
                                        access_alarm
                                    </Icon>
                                </span>
                            )}
                            <button
                                type="button"
                                className="step-control edit-step"
                                title="Edit step"
                                data-index={i}
                                onClick={handleEditStep}
                            >
                                <Icon>edit</Icon>
                            </button>
                            <button
                                type="button"
                                className="step-control remove-item"
                                title="Remove step"
                                data-index={i}
                                onClick={handleRemoveStep}
                            >
                                <Icon>clear</Icon>
                            </button>
                        </li>
                    );
                })}
            </ol>
            {editStep && (
                <div className="edit-step-wrapper">
                    <div className="edit-step">
                        <AddEditStep
                            heading="Edit step"
                            cancelCallback={cancelEditStepCallBack}
                            content={editStepContent}
                            stepIndex={editStepIndex}
                            buttonText="Finish edit"
                        />
                        <button
                            className="btn close flex-center"
                            type="button"
                            onClick={cancelEditStepCallBack}
                        >
                            Close
                            <Icon>close</Icon>
                        </button>
                    </div>
                </div>
            )}
            {addNewStepButton}
        </div>
    ) : (
        <>
            <p className="no-items-message">
                <Icon>edit_location</Icon> Currently no steps. Add some!
            </p>
            {addNewStepButton}
        </>
    );

    return allSteps;
};

export default EditingListSteps;
