import { useState } from 'react';
// import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import Nouislider from 'nouislider-react';
import convertToReadableTime from './readableTime';

const RangeTimer = (props) => {
    const {
        sliderId,
        timerNumber,
        defaultSliderValue,
        defaultStartValue,
        optionalClasses,
        labelText,
        // minimumValue,
        action,
    } = props;

    const [sliderValue, setSliderValue] = useState(defaultSliderValue);

    const onUpdate = (time) => {
        const newtimeArr = convertToReadableTime(time[0]);

        const setClockHands = (changeTime) => {
            // fun part - CLOCK HANDS
            const rawTime = changeTime ? changeTime : newtimeArr;
            const hourHand = rawTime.hours * 30;
            const minuteHand = rawTime.minutes * 6;

            const root = document.documentElement;
            const timer = timerNumber;
            root.style.setProperty(
                '--' + timer + '-minute-rotate',
                minuteHand + 'deg'
            );
            root.style.setProperty(
                '--' + timer + '-hour-rotate',
                hourHand + 'deg'
            );
        };
        setClockHands();

        setSliderValue(newtimeArr.readableTime);
        action(time[0]);
    };

    return (
        <div className="range-wrapper">
            {labelText ? <label htmlFor="prepTime">{labelText}</label> : null}
            <span id={sliderId} className="range-slider-text">
                {sliderValue}
            </span>
            <Nouislider
                className={'range-slider ' + optionalClasses}
                data-timer={timerNumber}
                range={{
                    min: [0],
                    '25%': [60, 5],
                    '50%': [120, 5],
                    '70%': [240, 5],
                    max: [10000],
                }}
                step={1}
                start={defaultStartValue ? defaultStartValue : [0]}
                connect="lower"
                onUpdate={onUpdate}
            />
        </div>
    );
};

export default RangeTimer;
