import { Component } from 'react';
import {
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from 'draft-js';
import { Icon } from 'react-materialize';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';

class Wysiwyg extends Component {
    constructor(props) {
        super(props);

        const editorState = this.props.content
            ? EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                      convertFromHTML(this.props.content)
                  )
              )
            : EditorState.createEmpty();

        this.state = {
            editorState: editorState,
            controlType: this.props.controlType,
            callBack: this.props.callBack,
            placeholderText: this.props.placeholderText,
        };
        this.focus = () => this.refs.editor.focus();
    }

    onChange = (editorState) => {
        this.setState({ editorState });
        let stepString = stateToHTML(editorState.getCurrentContent());
        if (stepString.replace(/(<([^>]+)>)/gi, '') && this.state.callBack) {
            this.state.callBack(stepString);
        }
    };

    toggleBlockType = (blockType) => {
        this.onChange(
            RichUtils.toggleBlockType(this.state.editorState, blockType)
        );
    };

    toggleInlineStyle = (inlineStyle) => {
        this.onChange(
            RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
        );
    };

    render() {
        const { editorState, controlType } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        const testAudio = () => {
            const audioText = convertToRaw(contentState).blocks[0].text;
            const msg = new SpeechSynthesisUtterance(audioText);
            window.speechSynthesis.speak(msg);
        };

        return (
            <div className="RichEditor-root">
                {/* <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                /> */}
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                    controlType={controlType}
                />
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        onChange={this.onChange}
                        placeholder={this.state.placeholderText}
                        ref="editor"
                        // blockRenderMap={extendedBlockRenderMap}
                    />
                </div>
                <div>
                    <p className="RichEditor-helper-text">
                        <strong>Tip:</strong> Highlight and bold text to match
                        the ingredients. It helps people follow your recipe
                    </p>
                    <button
                        className="btn btn-small flex-center"
                        type="button"
                        onClick={testAudio}
                        title="Play audio"
                    >
                        Test step audio <Icon>volume_up</Icon>
                    </button>
                </div>
            </div>
        );
    }
}

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        case 'new-block-type-name':
            return {
                // component: CustomComponent,
                editable: false,
            };
        default:
            return null;
    }
}

// const CustomComponent = ({ children }) => {
//     return (
//         <div>
//             <span> 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 </span>
//             {children}
//             <span> 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 </span>
//         </div>
//     );
// };

// const blockRenderMap = Immutable.Map({
//     'new-block-type-name': {
//         element: CustomComponent,
//     },
// });

// const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
//     blockRenderMap
// );

const StyleButton = ({ style, onToggle, active, label }) => {
    const onMouseDown = (e) => {
        e.preventDefault();
        onToggle(style);
    };

    const className = !active
        ? 'RichEditor-styleButton'
        : 'RichEditor-styleButton RichEditor-activeButton';

    return (
        <button
            type="button"
            className={'btn btn-small ' + className}
            onMouseDown={onMouseDown}
        >
            {label}
        </button>
    );
};

// const BLOCK_TYPES = [
//     { label: 'H1', style: 'header-one' },
//     { label: 'H2', style: 'header-two' },
//     { label: 'H3', style: 'header-three' },
//     { label: 'H4', style: 'header-four' },
//     { label: 'H5', style: 'header-five' },
//     { label: 'H6', style: 'header-six' },
//     { label: 'Blockquote', style: 'blockquote' },
//     { label: 'UL', style: 'unordered-list-item' },
//     { label: 'OL', style: 'ordered-list-item' },
//     { label: 'Code Block', style: 'code-block' },
//     { label: 'Fire', style: 'new-block-type-name' },
// ];

// const BlockStyleControls = ({ editorState, onToggle }) => {
//     const selection = editorState.getSelection();
//     const blockType = editorState
//         .getCurrentContent()
//         .getBlockForKey(selection.getStartKey())
//         .getType();

//     return (
//         <div className="RichEditor-controls">
//             {BLOCK_TYPES.map((type) => (
//                 <StyleButton
//                     key={type.label}
//                     active={type.style === blockType}
//                     label={type.label}
//                     onToggle={onToggle}
//                     style={type.style}
//                 />
//             ))}
//         </div>
//     );
// };

let inlineStyles = null;

const fullInlineStyles = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    // { label: 'Monospace', style: 'CODE' },
];

const boldOnly = [{ label: 'Bold', style: 'BOLD' }];

const InlineStyleControls = ({ editorState, onToggle, controlType }) => {
    var currentStyle = editorState.getCurrentInlineStyle();
    if (controlType === 'bold') {
        inlineStyles = boldOnly;
    } else {
        inlineStyles = fullInlineStyles;
    }

    return (
        <div className="RichEditor-controls ">
            {inlineStyles.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </div>
    );
};

export default Wysiwyg;
