import { Component } from 'react';
import { connect } from 'react-redux';
import { register } from '../../store/actions/authActions';
import 'materialize-css';
import { TextInput, Row, Col } from 'react-materialize';
import { NavLink } from 'react-router-dom';
import LoadingAnimation from '../modules/loadingAnimation';
// import { WithGoogleRecaptchaExample } from './recaptcha';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RECAPTCHA_KEY = '6LdrePwbAAAAADxYEdOwgnbwnuUWYdKXeWPRmPVZ';

class Register extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    };

    handleInputChange = e => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    handleOnSubmit = e => {
        e.preventDefault();
        this.props.register(this.state);
    };

    render() {
        const { auth, authError } = this.props;

        const taskStatus = auth.uid
            ? {
                  loadingState: 'animate-out',
                  status: 'task-complete',
                  text: 'Welcome!',
              }
            : null;

        if (auth.uid) {
            setTimeout(() => {
                return this.props.history.push('/');
            }, 2000);
        }

        return (
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <div className="page-wrapper register-page">
                    <form
                        className="register-form"
                        onSubmit={this.handleOnSubmit}>
                        <div className="container">
                            <Row s={12} m={12} l={12} xl={12}>
                                <h1 className="page-heading">Sign up</h1>
                            </Row>

                            <Row>
                                <TextInput
                                    type="email"
                                    id="email"
                                    label="Email"
                                    name="email"
                                    onChange={this.handleInputChange}
                                    required
                                    s={12}
                                    m={6}
                                    l={6}
                                    xl={6}
                                />
                            </Row>

                            <Row>
                                <TextInput
                                    type="password"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    onChange={this.handleInputChange}
                                    s={12}
                                    m={6}
                                    l={6}
                                    xl={6}
                                />
                            </Row>

                            <Row>
                                <TextInput
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    onChange={this.handleInputChange}
                                    s={12}
                                    m={6}
                                    l={6}
                                    xl={6}
                                />
                            </Row>

                            <Row>
                                <TextInput
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    onChange={this.handleInputChange}
                                    s={12}
                                    m={6}
                                    l={6}
                                    xl={6}
                                />
                            </Row>

                            <Row>{/* <WithGoogleRecaptchaExample /> */}</Row>

                            <Row>
                                <Col s={12} m={12} l={12} xl={12}>
                                    <button
                                        className="btn waves-effect waves-light"
                                        type="submit">
                                        Sign up
                                    </button>
                                </Col>
                            </Row>

                            <div className="signup-error">
                                {authError && (
                                    <Row>
                                        <Col s={12} m={12} l={12} xl={12}>
                                            <p>{authError}</p>
                                        </Col>
                                    </Row>
                                )}
                            </div>

                            <Row>
                                <Col s={12} m={12} l={12} xl={12}>
                                    <p>
                                        or if you've got an account,{' '}
                                        <NavLink
                                            to="/login"
                                            className="underline link-to-login">
                                            Sign in
                                        </NavLink>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </form>
                    {taskStatus && (
                        <LoadingAnimation loadingText={taskStatus} />
                    )}
                </div>
            </GoogleReCaptchaProvider>
        );
    }
}

const mapSatetoProps = state => {
    // console.log(state);
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        register: newUser => dispatch(register(newUser)),
    };
};

export default connect(mapSatetoProps, mapDispatchToProps)(Register);
