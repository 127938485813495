const initState = {};

const profileReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PROFILE_UPDATED':
            console.log('PROFILE UPDATED');
            return {
                ...state,
                updateSuccess: true,
            };

        case 'PROFILE_UDPATE_ERROR':
            console.log('PROFILE UPDATED ERROR ', action.err);
            return {
                ...state,
                err: action.err,
            };
        default:
    }

    return state;
};

export default profileReducer;
