import { Component } from 'react';
import { Row, Col, TextInput, Icon } from 'react-materialize';
import debounce from 'lodash/debounce';
import { FormRequirements } from '../forms/formRequirements';
import AddingTags from './addingTags';
import ProfileTags from './profileTags';
// import Wysiwyg from './wysiwyg';
// import RangeTimer from '../makerecipe/rangeTimer';

class EditProfileForm extends Component {
    formUiFields = {
        profilePreviewUrl: false,
        missingFields: false,
        updatingProfile: false,
        editorReady: true,
    };

    requiredFields = ['firstName', 'lastName'];

    // Adjust state for editing
    adjustProfileToEdit = () => {
        const fieldAdditions = { ...this.formUiFields };
        const profileEditingState = {
            ...fieldAdditions,
            ...this.props.profile,
        };

        // Add extra fields for special accounts. Users can't edit these though. Basically a UI thing

        if (profileEditingState.profileImage === 'undefined') {
            profileEditingState.profileImage = null;
            profileEditingState.tags = [];
        }

        if (profileEditingState.verified === 'undefined') {
            profileEditingState.verified = false;
        }

        if (profileEditingState.profileTitle === 'undefined') {
            profileEditingState.profileTitle = 'New member';
        }

        if (profileEditingState.displayName === 'undefined') {
            profileEditingState.displayName =
                profileEditingState.firstName +
                ' ' +
                profileEditingState.lastName;
        }

        return profileEditingState;
    };

    profileCleaner = (profile) => {
        const cleanProfileState = { ...profile };
        const formUifields = this.formUiFields;
        Object.keys(profile).forEach((field) => {
            if (field in formUifields) {
                delete cleanProfileState[field];
            }
        });

        return cleanProfileState;
    };

    state = this.adjustProfileToEdit();

    handleNameChange = (e) => {
        const textName = e.target.name;
        let textValue = e.target.value;

        if (e.target.type === 'checkbox') {
            textValue = e.target.checked;
        }

        this.setStateValue(textName, textValue);
    };

    setStateValue = debounce((name, value) => {
        this.setState({
            [name]: value,
        });
    }, 500);

    setStateNow = (name, value) => {
        this.setStateValue(name, value);
    };

    handleOnChangeFile = (e) => {
        const fileSize = e.target.files[0] && e.target.files[0].size < 5000000; // 5mb
        if (fileSize) {
            this.setState({
                profileImage: e.target.files[0],
                profilePreviewUrl: URL.createObjectURL(e.target.files[0]),
            });

            // For editing a recipe
            if (!this.state.profilePreviewUrl) {
                const newProfilePreviewObject = {
                    profilePreviewUrl: URL.createObjectURL(e.target.files[0]),
                };
                const prevState = this.state;
                const newState = { ...prevState, ...newProfilePreviewObject };
                newState.profileImage = e.target.files[0];

                this.setState(() => {
                    return newState;
                });
            }
        } else {
            alert('File chosen is larger than 5mb');
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const currentState = this.state;
        const updatingProfileInProgress = currentState.updatingProfile;
        const profile = this.profileCleaner(currentState);
        const formErrors = FormRequirements(profile, this.requiredFields);
        const profileIsInvalid = Object.keys(formErrors).length;

        if (profileIsInvalid) {
            this.setState({
                missingFields: true,
            });
        }

        if (!profileIsInvalid && !updatingProfileInProgress) {
            this.setState({
                missingFields: false,
                updatingProfile: true,
            });

            // Send recipe to action
            this.props.action(profile);
            // console.log(profile);
        }
    };

    render() {
        const profilePreview = !this.state.profilePreviewUrl
            ? this.state.profileImage
            : this.state.profilePreviewUrl;

        // Class for someone trying to edit the field through the inspector
        const funnyGuy = this.state.profileTitle === 'kitchenrat';

        // const recipes = this.props.recipes;
        const isVerifired = this.state.verified;

        return (
            <form className="edit-profile-form" onSubmit={this.handleSubmit}>
                <Col
                    s={12}
                    m={6}
                    l={6}
                    xl={6}
                    // className="push-m6 push-l6 push-xl6"
                >
                    <Col
                        className="profile-wrapper"
                        s={12}
                        m={12}
                        l={12}
                        xl={12}
                    >
                        <Col s={12} m={6} l={6} xl={6}>
                            <div className="profile-avatar-wrapper">
                                {profilePreview ? (
                                    <img
                                        className="profile-preview"
                                        src={profilePreview}
                                        alt="profile"
                                    />
                                ) : (
                                    <Icon className="no-avatar">person</Icon>
                                )}
                            </div>
                        </Col>

                        <TextInput
                            id="profileThumbnail"
                            className="waves-effect waves-light  validate"
                            name="profileThumbnail"
                            type="file"
                            accept="image/png, image/jpeg"
                            label={
                                profilePreview
                                    ? 'Pick a new profile image'
                                    : 'Choose profile image'
                            }
                            onChange={this.handleOnChangeFile}
                            s={12}
                            m={6}
                            l={6}
                            xl={6}
                        >
                            <span className="file-size-helper flex-center col s12 m12 l12 xl12">
                                Max file size 5mb. Square photos work best
                            </span>
                        </TextInput>
                    </Col>
                </Col>
                <Col
                    s={12}
                    m={6}
                    l={6}
                    xl={6}
                    // className="pull-m6 pull-l6 pull-xl6"
                >
                    <TextInput
                        id="displayName"
                        className="validate"
                        label="Display name"
                        type="text"
                        required
                        name="displayName"
                        autoComplete="off"
                        onChange={this.handleNameChange}
                        data-length={20}
                        maxLength="20"
                        defaultValue={this.state.displayName}
                        s={12}
                        m={12}
                        l={12}
                        xl={12}
                    />

                    <TextInput
                        id="profileTitle"
                        name="profileTitle"
                        className="validate"
                        label="Profile tagline"
                        type="text"
                        required
                        autoComplete="off"
                        onChange={this.handleNameChange}
                        data-length={25}
                        maxLength="25"
                        defaultValue={
                            this.state.profileTitle
                                ? this.state.profileTitle
                                : 'New member'
                        }
                        s={12}
                        m={12}
                        l={12}
                        xl={12}
                    />

                    {/* {isVerifired ? (
                        <TextInput
                            id="profileTitle"
                            name="profileTitle"
                            className="validate"
                            label="Profile tagline"
                            type="text"
                            required
                            autoComplete="off"
                            onChange={this.handleNameChange}
                            data-length={20}
                            maxLength="20"
                            defaultValue={this.state.profileTitle}
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        />
                    ) : (
                        <Select
                            id="profileTitle"
                            name="profileTitle"
                            onChange={this.handleNameChange}
                            multiple={false}
                            label="Profile Title"
                            s={12}
                            m={12}
                            l={6}
                            xl={6}
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: true,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250,
                                },
                            }}
                            defaultValue={this.state.profileTitle}
                        >
                            {funnyGuy ? (
                                <option value="Kitchen rat">Kitchen rat</option>
                            ) : (
                                <>
                                    <option value="New member">
                                        New member
                                    </option>
                                    <option value="Food lover">
                                        Food lover
                                    </option>
                                    <option value="Grill master">
                                        Grill master
                                    </option>
                                    <option value="Loves to cook">
                                        Loves to cook
                                    </option>
                                    <option value="Nom nom monster">
                                        Nom nom monster
                                    </option>
                                    <option value="Overcooker">
                                        Overcooker
                                    </option>
                                </>
                            )}
                        </Select>
                    )} */}

                    {funnyGuy && (
                        <span className="col s12 m12 l12 xl12 funnyguy-helper-text">
                            you know what you did...
                        </span>
                    )}
                    {isVerifired ? null : (
                        <p className="profile-title-helper col s12 m12 l12 xl12">
                            Note: You can have a cool customised URL if you
                            verify your identity.
                        </p>
                    )}

                    {/* <Row>
                        <TextInput
                            id="firstName"
                            className="validate"
                            label="First name"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            onChange={this.handleNameChange}
                            data-length={20}
                            maxLength="20"
                            defaultValue={this.state.firstName}
                            required
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        />
                        <TextInput
                            id="lastName"
                            className="validate"
                            label="Last name"
                            type="text"
                            name="lastName"
                            autoComplete="off"
                            required
                            onChange={this.handleNameChange}
                            data-length={20}
                            maxLength="20"
                            defaultValue={this.state.lastName}
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        />
                    </Row> */}
                </Col>
                <Row>
                    <Col s={12} m={6} l={6} xl={6}>
                        <Col s={12} m={12} l={12} xl={12}>
                            <h2 className="edit-profile-heading">
                                Your details
                            </h2>
                        </Col>
                        <TextInput
                            id="firstName"
                            name="firstName"
                            className="validate"
                            label="First name"
                            type="text"
                            required
                            autoComplete="off"
                            onChange={this.handleNameChange}
                            data-length={20}
                            maxLength="20"
                            defaultValue={this.state.firstName}
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        />

                        <TextInput
                            id="lastName"
                            name="lastName"
                            className="validate"
                            label="Last name"
                            type="text"
                            required
                            autoComplete="off"
                            onChange={this.handleNameChange}
                            data-length={20}
                            maxLength="20"
                            defaultValue={this.state.lastName}
                            s={12}
                            m={12}
                            l={12}
                            xl={12}
                        />
                    </Col>

                    <Col s={12} m={6} l={6} xl={6}>
                        <Col s={12} m={12} l={12} xl={12}>
                            <h2 className="edit-profile-heading">
                                Profile tags
                            </h2>
                        </Col>

                        <AddingTags
                            updateTags={this.setStateNow}
                            currentTags={this.state.tags}
                            suggestedTags={ProfileTags}
                            limit={3}
                        />
                    </Col>
                </Row>

                {/* <Row>
                    <Col s={12} m={12} l={12} xl={12}>
                        <h2>Testing</h2>
                        <Wysiwyg />
                        <RangeTimer
                            sliderId="prepTimeText"
                            labelText="Prep time:"
                            minimumValue="0"
                            optionalClasses="prep-time slider-one"
                            timerNumber="one"
                        />
                        <RangeTimer
                            sliderId="cookTimeText"
                            labelText="Cook time:"
                            optionalClasses="cook-time slider-two"
                            timerNumber="two"
                        />
                    </Col>
                </Row> */}

                <Row>
                    <Col
                        s={12}
                        m={6}
                        l={6}
                        xl={6}
                        className="center-align flex-center edit-profile-submit-wrapper"
                    >
                        <button
                            type="submit"
                            className="btn btn-large submit-btn waves-effect waves-light flex-center"
                            disabled={
                                this.state.updatingProfile ? 'disabled' : null
                            }
                        >
                            <Icon>create</Icon>Update profile
                        </button>
                    </Col>
                </Row>
            </form>
        );
    }
}

export default EditProfileForm;
