const Privacy = () => {
    return (
        <div className="page-wrapper privacy-page">
            <h1 className="banner-text">Privacy policy</h1>

            <div className="rules-wrapper">
                <p>Before we getting into it...</p>

                <div className="ethics-message">
                    <h2>
                        My Mothers recipes will never solicit credit card
                        details or payment through emails or phone calls
                    </h2>
                    <p>Here are a few tips to spot a scam:</p>
                    <ol>
                        <li>
                            Check that emails are from "@mymothers.recipes".
                            Ensure it doesn't have an extra letter or digit
                            hidden at after the @ symbol
                        </li>
                        <li>
                            When browsing to a mymothers.recipes link, check
                            that the url is actually "mymothers.recipes" and
                            ensure there is a lock symbol at the start.{' '}
                        </li>
                        <li>
                            Don't give out your password. If you have forgotten
                            it, use the recover password link on the login page
                            and follow the link in the email we send you. Refer
                            back to the first point when checking the email
                        </li>
                    </ol>
                </div>

                <h2>Personal and Profile information</h2>
                <p>
                    Personal and profile information provided to My Mothers
                    Recipes is upon the users consent. The information provided
                    will be used inside in app and in the app alone to enhance
                    the user experience such as search and tagging.
                </p>
                <h3>Passwords and email login</h3>
                <p>
                    User passwords are encrypted and authenticated through
                    Google Firebase service and is kept confidential even from
                    My Mothers Recipes. It is up to the user to provide a valid
                    email to succussfully use the recover password feature found
                    on the login page.
                </p>
                <h3>Images</h3>
                <p>
                    Profiles images are kept by My Mothers Recipes upon the
                    users consent. Only 1 profile image is held within the
                    database until the user changes their profile image where
                    the previous image is automatically deleted. Deletion of
                    remaining profile images will be upon the users discretion
                    when their account is removed.
                </p>

                <h2>Recipes</h2>
                <p>
                    Recipes set to public visibility will be available for the
                    public. It is the sole responsibilty of the creator to set
                    this visibility setting. Images used deleted from our
                    database when replaced by the user.
                </p>
            </div>
        </div>
    );
};

export default Privacy;
