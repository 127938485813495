import { useContext, useEffect, useRef } from 'react';
import { StepContext } from '../../../store/contexts/stepContext';
import { CookingTimersContext } from '../../../store/contexts/cookingTimersContext';
import CountdownTimer from '../recipes/countdownTimer';
import moment from 'moment';
import { Icon } from 'react-materialize';
import ReactHtmlParser from 'react-html-parser';
import * as googleTTS from 'google-tts-api'; // ES6 or TypeScript

const StepsListLayout = (props) => {
    const { steps } = useContext(StepContext);
    const { cookingTimers } = useContext(CookingTimersContext);
    const liveMode = props.liveMode;
    const onMount = useRef(true);
    const isCooking = cookingTimers ? true : false;

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            const cookingTimersString = JSON.stringify(cookingTimers);
            localStorage.setItem('cookingTimers', cookingTimersString);
        }
    }, [cookingTimers]);

    const handlePlayAudio = (e) => {
        let textOnly = e.target.dataset.text.replace(/(<([^>]+)>)/gi, '');
        var msg = new SpeechSynthesisUtterance(textOnly);
        window.speechSynthesis.speak(msg);

        // get audio URL
        const results = googleTTS.getAllAudioUrls(textOnly, {
            lang: 'en-GB',
            slow: false,
            host: 'https://translate.google.com',
            splitPunct: ',.?',
        });

        console.log(results);
    };

    const listSteps = steps.length ? (
        <div className="glide">
            <div data-glide-el="track" className="glide__track">
                <ol className="steps-list glide__slides">
                    {steps.map((step, i) => {
                        // Check if just text or complex step
                        const stepIsComplex = typeof step === 'object';
                        const stepContent = stepIsComplex ? step.content : step;
                        const stepHasTimer = stepIsComplex && 'time' in step;
                        const originalTimerInSeconds = step.time
                            ? step.time * 60
                            : '';

                        const hasTimeSaved = isCooking && cookingTimers[i];
                        let stepTimerInSeconds =
                            step.time && hasTimeSaved
                                ? cookingTimers[i].timeRemaining
                                : originalTimerInSeconds;

                        const stepPauseState = hasTimeSaved
                            ? cookingTimers[i].paused
                            : true;

                        if (hasTimeSaved) {
                            stepTimerInSeconds = !cookingTimers[i].paused
                                ? stepTimerInSeconds -
                                  (moment().unix() - cookingTimers[i].startTime)
                                : cookingTimers[i].timeRemaining;

                            if (stepTimerInSeconds <= 0) stepTimerInSeconds = 0;
                        }

                        return (
                            <li
                                className={
                                    'step glide__slide' +
                                    (stepHasTimer ? ' has-timer' : '')
                                }
                                key={i}
                            >
                                <button
                                    className="play-audio btn btn-small"
                                    type="button"
                                    onClick={handlePlayAudio}
                                    data-text={stepContent}
                                    title="Play step audio"
                                >
                                    <Icon>volume_up</Icon>
                                </button>
                                {stepContent.split('\n').map((item, i) => {
                                    return (
                                        <div key={i}>
                                            {/* {item} */}
                                            {ReactHtmlParser(item)}
                                        </div>
                                    );
                                })}

                                {liveMode && stepHasTimer ? (
                                    <CountdownTimer
                                        duration={stepTimerInSeconds}
                                        startPaused={stepPauseState}
                                        stepIndex={i}
                                        originalTimerInSeconds={
                                            originalTimerInSeconds
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                            </li>
                        );
                    })}
                </ol>
            </div>
            <div className="glide__arrows temp-show" data-glide-el="controls">
                <button
                    className="btn glide__arrow glide__arrow--left"
                    data-glide-dir="<"
                >
                    Prev step
                </button>
                <button
                    className="btn glide__arrow glide__arrow--right"
                    data-glide-dir=">"
                >
                    Next step
                </button>
            </div>
        </div>
    ) : (
        <p>watch your step...because you aint got one. Boom. Roasted.</p>
    );
    return listSteps;
};

export default StepsListLayout;
