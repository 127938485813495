import { createContext, useState } from 'react';

export const BookMarksContext = createContext();

const BookMarksContextProvider = (props) => {
    const [bookMarks, setBookMarks] = useState(props.bookMarks);

    return (
        <BookMarksContext.Provider value={{ bookMarks, setBookMarks }}>
            {props.children}
        </BookMarksContext.Provider>
    );
};

export default BookMarksContextProvider;
