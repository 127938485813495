const initState = {};

const uploadImageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPLOAD_FILE_SUCCESS':
            console.log('File uploaded');
            return state;

        case 'UPLOAD_FILE_ERROR':
            console.log('File upload error: ', action.err);
            return {
                ...state,
                uploadFileError: action.err,
            };
        default:
    }

    return state;
};

export default uploadImageReducer;
