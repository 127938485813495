import { useContext, useEffect, useRef } from 'react';
import { IngredientContext } from '../../../store/contexts/ingredientContext';
import { Icon } from 'react-materialize';

const EditingIngredientsList = (props) => {
    const { ingredients, dispatch } = useContext(IngredientContext);
    const onMount = useRef(true);
    const { updateIngredients } = props;

    useEffect(() => {
        if (onMount.current) {
            onMount.current = false;
        } else {
            updateIngredients('ingredients', ingredients);
        }
    }, [ingredients, updateIngredients]);

    const allIngredients = ingredients.length ? (
        <ul className="ingredients-list adding-items">
            {ingredients.map((ingredient, i) => {
                return (
                    <li className="list-item ingredient" key={i}>
                        {ingredient}

                        <button
                            type="button"
                            className="ingredient-control remove-item"
                            title="remove ingredient"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch({
                                    type: 'REMOVE_INGREDIENT',
                                    index: i,
                                });
                            }}
                        >
                            <Icon>clear</Icon>
                        </button>
                    </li>
                );
            })}
        </ul>
    ) : (
        <p className="no-items-message">
            <Icon>edit_location</Icon>Currently no ingredients. Add some!
        </p>
    );
    return allIngredients;
};

export default EditingIngredientsList;
