import { createContext, useReducer } from 'react';
import { stepReducer } from '../reducers/stepReducer';

export const StepContext = createContext();

const StepContextProvider = (props) => {
    const [steps, dispatch] = useReducer(stepReducer, [], () => {
        return props.steps;
    });

    return (
        <StepContext.Provider value={{ steps, dispatch }}>
            {props.children}
        </StepContext.Provider>
    );
};

export default StepContextProvider;
