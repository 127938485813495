import { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-materialize';
import EditProfileForm from '../modules/forms/editProfileForm';
import { updateProfile } from '../../store/actions/userActions';
import LoadingAnimation from '../modules/loadingAnimation';

class EditProfile extends Component {
    state = {
        updatingProfile: null,
        updateSuccess: false,
    };

    formAction = (response) => {
        this.setState({
            updatingProfile: true,
        });

        this.props.updateProfile(response, this.props.profile);
        // console.log(response, this.props.profile);
    };

    render() {
        const {
            auth,
            profile,
            recipes,
            updateSuccess,
            recipesLoaded,
        } = this.props;
        if (!auth.uid) {
            return <Redirect to="/" />;
        }

        if (auth.uid && profile && recipesLoaded) {
            const updatingProfile = this.state.updatingProfile;

            const taskStatus = updateSuccess
                ? {
                      loadingState: '',
                      status: 'task-complete',
                      text: 'All done!',
                  }
                : {
                      loadingState: 'animate-in',
                      status: 'in-progress',
                      text: 'Updating profile',
                  };

            const profilePath = profile.alias || auth.uid;

            if (updateSuccess && updatingProfile) {
                setTimeout(() => {
                    return this.props.history.push('/' + profilePath);
                }, 1300);
            }

            return (
                <div>
                    <div className="page-wrapper edit-profile-page">
                        <Row className="edit-profile-header">
                            <Col l={12} m={12} s={12} xl={12}>
                                <h1 className="page-heading center-align">
                                    Edit profile
                                </h1>
                            </Col>

                            <EditProfileForm
                                profile={profile}
                                recipes={recipes}
                                action={this.formAction}
                            />
                        </Row>
                    </div>

                    {updatingProfile && (
                        <LoadingAnimation loadingText={taskStatus} />
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state) => {
    const auth = state.firebase.auth;
    const users = state.firestore.data.users;
    const profile = users ? users[auth.uid] : null;
    const recipes = state.firestore.data.recipes;
    const updateSuccess = state.profile.updateSuccess;

    return {
        auth: auth,
        profile: profile,
        recipes: recipes,
        recipesLoaded: state.firestore.data.hasOwnProperty('recipes'),
        updateSuccess: updateSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (state, originalState) => {
            dispatch(updateProfile(state, originalState));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((state) => {
        return [
            {
                collection: 'users',
                doc: state.auth.uid,
            },
            {
                collection: 'recipes',
                where: [['authorId', '==', state.auth.uid]],
            },
        ];
    })
)(EditProfile);
