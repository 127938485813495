import { useState } from 'react';
import { Row, Col } from 'react-materialize';
import './index.scss';

const BecomeAFan = (props) => {
    const { isFan } = props;
    const [becomeAFan, setBecomeAFan] = useState(isFan);

    const handleBecomingAFan = () => {
        setBecomeAFan(!becomeAFan);
    };
    return (
        <Row>
            <Col s={12} m={12} l={12} xl={12} className="flex-center">
                {becomeAFan ? (
                    <button
                        className="btn browser-default user-fan clean flex-center"
                        onClick={handleBecomingAFan}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 25 27.35"
                            strokeWidth="1"
                            stroke="#000000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon icon-tabler"
                        >
                            <g>
                                <path d="M6.91,1a.85.85,0,0,1,.5.1,4.5,4.5,0,0,1-.09,1.57,9,9,0,0,0,.64,6,15.93,15.93,0,0,0,1.48,2.16l0,.05a2.49,2.49,0,0,0-.6.2,2.72,2.72,0,0,0-1.11.94A10.18,10.18,0,0,1,3.32,8.53,4.79,4.79,0,0,1,2.24,4.39,5.63,5.63,0,0,1,6.91,1m0-1A6.64,6.64,0,0,0,1.29,4.08c-1.38,4.3,4.79,9,6.64,9A.71.71,0,0,0,8.24,13c.32-.18.4-.68,1.06-1a1.92,1.92,0,0,1,.8-.17c.28,0,.52.06.7.06a.34.34,0,0,0,.26-.09c.35-.44-1.32-1.89-2.21-3.58C6.94,4.62,9.39,1.35,8,.3A1.87,1.87,0,0,0,6.91,0Z" />
                                <path d="M19.65,10.88a4.16,4.16,0,0,1,3.24,1.19,5.69,5.69,0,0,1,1,4.72c-.2.89-.54,1.34-.71,1.41a4.44,4.44,0,0,1-1.32-.87,9.07,9.07,0,0,0-5.54-2.47H16a17.13,17.13,0,0,0-2.29.2h-.05a2.84,2.84,0,0,0,.12-.62A2.73,2.73,0,0,0,13.48,13a10.4,10.4,0,0,1,6.17-2.14m0-1c-3.42,0-7.21,2.06-7.22,3.07,0,.37.38.68.32,1.41-.09.93-.83,1.3-.7,1.62,0,.15.24.2.54.2.73,0,2.07-.32,3.37-.32h.29c3.75.15,5.41,3.34,6.9,3.34a1.09,1.09,0,0,0,.39-.08c1.6-.67,2.23-5.36.1-7.72a5.24,5.24,0,0,0-4-1.52Z" />
                                <path d="M8.13,16.6a3.39,3.39,0,0,0,.49.4,2.77,2.77,0,0,0,1.39.42,10.06,10.06,0,0,1-.49,5.52c-.47,1.26-1.35,2.86-2.95,3.29a3.58,3.58,0,0,1-1,.12A6.18,6.18,0,0,1,1.5,24.53c-.41-.45-.51-.76-.5-.87s.86-.54,1.38-.77A9.14,9.14,0,0,0,7.1,19.07a15.2,15.2,0,0,0,1-2.41l0-.06M8,14.79c-.55,0-.86,2.15-1.78,3.79-2,3.57-6.09,3.28-6.23,5-.11,1.51,2.83,3.77,5.62,3.77a4.47,4.47,0,0,0,1.21-.16c4.66-1.25,5-10,3.73-10.68-.32-.17-.79,0-1.4-.36-.79-.5-.79-1.33-1.13-1.36Z" />
                                <path d="M10.41,13.55a.58.58,0,1,1-.58.58.58.58,0,0,1,.58-.58m0-1A1.58,1.58,0,1,0,12,14.13a1.58,1.58,0,0,0-1.58-1.58Z" />
                            </g>
                        </svg>
                    </button>
                ) : // (
                //     <button
                //         className="btn browser-default user-not-fan flex-center"
                //         onClick={handleBecomingAFan}
                //     >
                //         Become a fan
                //     </button>
                // )
                null}
            </Col>
        </Row>
    );
};

export default BecomeAFan;
