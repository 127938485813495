const initState = {
    authError: null,
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log('Login error');
            return {
                ...state,
                authError: 'Login failed',
            };

        case 'LOGIN_SUCCESS':
            console.log('Login success');
            return {
                ...state,
                authError: null,
            };

        case 'SIGNOUT_SUCCESS':
            console.log('Signout success');
            return state;

        case 'SIGNUP_SUCCESS':
            console.log('Signup success');
            return {
                ...state,
                authError: null,
            };

        case 'SIGNUP_ERROR':
            console.log('Signup error');
            return {
                ...state,
                authError: action.err.message,
            };

        case 'PASSWORD_RECOVER_SENT':
            console.log('Password recover sent');
            return {
                ...state,
                recoverSuccess: true,
            };

        case 'PASSWORD_RECOVER_SENT_ERROR':
            console.log('Password recover sent error');
            return {
                ...state,
                recoverError: action.error.message,
            };

        case 'PASSWORD_RESET_SUCCESS':
            return {
                ...state,
                resetSuccess: true,
            };

        case 'PASSWORD_RESET_ERROR':
            return {
                ...state,
                resetError: action.error.message,
            };

        default:
            return state;
    }
};

export default authReducer;
