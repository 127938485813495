import RecipesList from '../modules/recipes/recipeslist';
import ListMembers from '../modules/profile/listMembers';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Home = (props) => {
    const { recipes, users } = props;

    return (
        <div className="page-wrapper home-page">
            <h1 className="banner-text">
                write it down. <br />
                keep it forever. <br />
                {/* share it if you want. */}
            </h1>

            <br />
            <br />
            <div className="center-align">
                <Link
                    to="/addrecipe"
                    className="homepage-create-button btn waves-effect waves-light"
                >
                    Create a recipe
                </Link>
            </div>
            <br />

            {/* <p className="center-align homepage-sec-msg">
                and if you want to share it, we'll stop them from
                <br /> burning the eggs.
            </p>
            <br />
            <br /> */}
            <br />
            <RecipesList recipesList={recipes} />
            <br />
            <br />

            <ListMembers users={users} />
            <br />
            <br />
            <div className="beta-message">
                <p>
                    <strong>
                        Note for you lucky people seeing first stage release:
                    </strong>
                    This is a temporary homepage just to give you a feel of
                    content and clicking around. It'll be replaced with more
                    useful search/discover features.
                    <br />
                    <br />
                    If you want to see things happen, check out cooking mode on{' '}
                    {
                        <Link
                            className="homepage-promo-link"
                            to="/recipe/lKVONnvFSRynsXr2OkJ5"
                        >
                            Fried eggs
                        </Link>
                    }
                    . Its the recipe I test/add all features to.
                    <br />
                    And if you want to <i>make</i> things happen, create an
                    account, add some public/private recipes, edit your profile,
                    bookmark some recipes, make lists and edit things.
                    <br />
                    <br />
                    Enjoy! <br />
                    Will.
                </p>
            </div>

            {/* <Notifications /> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    const recipes = state.firestore.data.recipes;
    const users = state.firestore.data.users;
    return {
        auth: state.firebase.auth,
        recipes: recipes,
        users: users,
    };
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect((state) => [
        {
            collection: 'recipes',
            where: ['visibility', '==', 'public'],
            orderBy: ['creationDate', 'desc'],
            limit: 9,
            // doc: 'lKVONnvFSRynsXr2OkJ5',
        },
        { collection: 'users', orderBy: ['creationDate', 'desc'], limit: 9 },
    ])
)(Home);
