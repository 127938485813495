import moment from 'moment';

const convertToReadableTime = (time) => {
    const rawTime = time;

    const hourPart = moment
        .utc()
        .startOf('day')
        .add({ minutes: rawTime })
        .format('H');

    const minutePart = moment
        .utc()
        .startOf('day')
        .add({ minutes: rawTime })
        .format('mm');

    let hourText = '';
    let minuteText = '';

    if (hourPart === '1') {
        hourText = '1hr';
    } else if (hourPart > 1) {
        hourText = hourPart + 'hrs';
    }

    if (minutePart === '00') {
        minuteText = '';
    } else if (minutePart < '10') {
        minuteText = ' ' + minutePart.substr(1) + 'mins';
    } else {
        minuteText = ' ' + minutePart + 'mins';
    }

    return {
        readableTime: hourText + minuteText,
        minutes: minutePart,
        hours: hourPart,
    };
};

export default convertToReadableTime;
