import { NavLink } from 'react-router-dom';
import 'materialize-css';

const Navigation = () => {
    return (
        <div className="navigation temp-hide">
            <nav>
                <NavLink to="/" exact className="home-link nav-link">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-home"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#000000"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <polyline points="5 12 3 12 12 3 21 12 19 12" />
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                    </svg>
                    <span className="nav-text hide-on-small-only">
                        {/* mymothers.recipes */}
                        Home
                    </span>
                </NavLink>
                <NavLink to="/library" exact className="nav-link">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-book"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#000000"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                        <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                        <line x1="3" y1="6" x2="3" y2="19" />
                        <line x1="12" y1="6" x2="12" y2="19" />
                        <line x1="21" y1="6" x2="21" y2="19" />
                    </svg>
                    <span className="nav-text hide-on-small-only">Library</span>
                </NavLink>
                {/* <a
                    href="/"
                    // className="warm-apple colour"
                    className="colour"
                    data-bgcolor="warm-apple"
                    disabled
                >
                    <Icon>search</Icon>
                    apple
                </a> */}
                {/* <Link
                    to="/friends"
                    // className="warm-banana colour"
                    className="colour"
                    data-bgcolor="warm-banana"
                >
                    <Icon>group</Icon>
                    friends
                </Link> */}

                {/* <a
                    href="/"
                    // className="warm-date colour"
                    className="colour"
                    data-bgcolor="warm-date"
                >
                    <Icon>star</Icon>
                    date
                </a> */}
            </nav>
        </div>
    );
};

export default Navigation;
