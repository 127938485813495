import { Row, Col } from 'react-materialize';

const CopyrightRules = () => {
    return (
        <Row>
            <Col s={12} m={12} l={12} xl={12} className="center-align">
                <button
                    className="waves-effect waves-light btn browser-default modal-trigger"
                    href="#copyright"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    Recipe copyright guide
                </button>

                <div id="copyright" className="modal modal-fixed-footer">
                    <div className="copyright-guide modal-content">
                        <h2 className="copyright-header">Recipe guide</h2>
                        <p className="copyright-description">
                            A guide to help you safely add and protect your
                            recipes
                        </p>
                        <br />
                        <p>but before we get into it...</p>
                        <p className="ethics-message">
                            <span className="tag-line">
                                Don't be{' '}
                                <span className="italic inline">that</span>{' '}
                                person.
                            </span>{' '}
                            If it isn't entirely yours, just mention them/the
                            source if they have an account or in the
                            description.{' '}
                            <span className="tag-line-example">
                                e.g inspired by{' '}
                                <span className="paul-joke">
                                    <span className="paul-wrapper">
                                        <span className="paul">Paul</span>
                                    </span>{' '}
                                    <span className="holly-wrapper">
                                        <span className="holly">Holly</span>
                                    </span>
                                    wood
                                </span>
                            </span>
                            <br /> It's the right thing to do. Be like Jacinda.
                        </p>
                        <br />
                        <h3 className="tips-heading">Tips and guidelines</h3>
                        <br />
                        <ul className="ethics-list">
                            <li>
                                <h4 className="italic">
                                    "Add extra icing on the cake"
                                </h4>
                                <p>
                                    Put some backstory in the description.
                                    Explain how the recipe came about. Or make
                                    up something:{' '}
                                    <span className="italic">
                                        "A talking space coyote came to me in a
                                        dream".
                                    </span>
                                </p>
                                <p>
                                    BUT, don't let the that throw you off.
                                    Friends and family will probably read it,
                                    even though its hidden by default.
                                </p>
                                <p>
                                    To protect your recipes, write it your own
                                    words. They'll love it.
                                </p>
                                <br />
                            </li>

                            <li>
                                <h4 className="italic">
                                    "Substitute honey for sugar"
                                </h4>
                                <p>
                                    Change/add an ingredient or 2. Or phrase it
                                    in your own way.
                                </p>
                                <p>Use a different unit of measurement.</p>
                                <p>Make it unique.</p>
                                <br />
                            </li>

                            <li>
                                <h4 className="italic">
                                    "Picking your own ingredients"
                                </h4>
                                <p>
                                    Let's be honest, there aren't many ways to
                                    make chocolate chip muffins.
                                </p>
                                <p>
                                    Ensure you don't copy and paste
                                    steps/ingredients/titles/images from other
                                    sources without permission.
                                </p>
                                <p>But if you do, please just be a human.</p>
                                <p>
                                    Ask for permission or give them a shout out
                                    in the description.
                                </p>
                                <p>
                                    No one cares or would even believe that you
                                    invented carrot cake.
                                </p>
                            </li>
                        </ul>
                        <br />
                        <br />
                        <br />
                        <p>
                            If you happen to have made it this far, and its
                            still stressing you out, just make the recipe
                            private. Or you know....be like Jacinda.
                        </p>
                    </div>

                    <div className="modal-footer">
                        <button
                            href="#!"
                            className="btn browser-default close-copyright-guide modal-close waves-effect waves-green btn-flat"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            Ok, I got it!
                        </button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CopyrightRules;
