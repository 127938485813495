import { Component } from 'react';
import RecipesList from '../modules/recipes/recipeslist';
import BecomeAFan from '../modules/profile/becomeAFan';
import { signOut } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
// import { Link } from 'react-router-dom';
import 'materialize-css';
import { Row, Col } from 'react-materialize';
import ProfileLayout from '../modules/profile/profileLayout';

class Profile extends Component {
    render() {
        const {
            auth,
            profileUserUid,
            profile,
            recipes,
            usersLoaded,
            recipesLoaded,
        } = this.props;

        // if (!auth.uid) {
        //     return <Redirect to="/signin" />;
        // }

        if (usersLoaded && recipesLoaded) {
            if (profile) {
                const viewerIsAuthor =
                    profileUserUid === auth.uid ? true : false;

                const isFan =
                    profile.fans &&
                    profile.fans.some((fans) => fans === auth.uid);

                return (
                    <div>
                        {viewerIsAuthor ? (
                            <p className="author-toolbar center-align temp-hide">
                                This is you! You can see all your recipes
                            </p>
                        ) : null}
                        <div className="page-wrapper account-page">
                            <ProfileLayout profile={profile} />

                            <Row>
                                <Col
                                    className="center-align"
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                >
                                    {/* {viewerIsAuthor ? (
                                        <>
                                            <div className="profile-sign-out">
                                                <Link
                                                    to="/"
                                                    className="btn sign-out waves-effect waves-light"
                                                    onClick={this.props.signOut}
                                                >
                                                    <span>Sign out</span>
                                                </Link>
                                            </div>
                                            <p>
                                                <Link
                                                    to="/profile/edit"
                                                    title="Edit profile"
                                                    className="btn link waves-effect waves-light"
                                                >
                                                    Edit profile
                                                </Link>{' '}
                                                <Link
                                                    to="/addrecipe"
                                                    title="Add a recipe"
                                                    className="btn link waves-effect waves-light"
                                                >
                                                    Add a recipe
                                                </Link>
                                            </p>
                                        </>
                                    ) : null} */}
                                    {/* <Col
                                s={12}
                                m={12}
                                l={12}
                                xl={12}
                                className="center-align"
                            >
                                <p>423 subscribers</p>
                            </Col> */}
                                    {!viewerIsAuthor && auth.uid && (
                                        <BecomeAFan isFan={isFan} />
                                    )}
                                </Col>
                            </Row>
                            {/* <Row>
                            <Col s={12} m={4} l={4} xl={4}>
                                <h4>public recipes</h4>
                            </Col>
                        </Row> */}
                            {recipes && (
                                <RecipesList
                                    recipesList={recipes}
                                    viewerIsAuthor={viewerIsAuthor}
                                />
                            )}

                            {!recipes && (
                                <Col
                                    className="profile-banner center-align"
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                >
                                    <p>No recipes added.....yet..</p>
                                </Col>
                            )}
                        </div>
                    </div>
                );
            } else {
                return <p>Page doesn't exist.</p>;
            }
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const profileId = ownProps.match.params.profile_id;
    const alias = state.firestore.data.alias;
    const profileUserUid =
        alias && alias[profileId] ? alias[profileId].uid : profileId;
    const users = state.firestore.data.users;
    const recipes = state.firestore.data.recipes;

    const profile = users ? users[profileUserUid] : null;
    return {
        auth: state.firebase.auth,
        usersLoaded: state.firestore.data.hasOwnProperty('users'),
        profile: profile,
        profileUserUid: profileUserUid,
        recipes: recipes,
        recipesLoaded: state.firestore.data.hasOwnProperty('recipes'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((state) => {
        const profileUrl = state.alias
            ? state.alias[state.profileUserUid]
            : state.profileUserUid;

        const query =
            state.profileUserUid === state.auth.uid
                ? [['authorId', '==', state.profileUserUid]]
                : [
                      ['visibility', '==', 'public'],
                      ['authorId', '==', state.profileUserUid],
                  ];

        return [
            {
                collection: 'alias',
                doc: state.profileUserUid,
            },
            {
                collection: 'users',
                doc: profileUrl,
            },
            {
                collection: 'recipes',
                where: [query],
            },
        ];
    })
)(Profile);
