import { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../store/actions/authActions';
import 'materialize-css';
import { TextInput, Row, Col } from 'react-materialize';
import LoadingAnimation from '../modules/loadingAnimation';

class ChangePassword extends Component {
    state = {
        password: '',
        confirmPassword: '',
        passwordError: false,
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    handleOnSubmit = (e) => {
        e.preventDefault();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const validationCode = urlParams.get('oobCode');

        if (
            this.state.password === this.state.confirmPassword &&
            this.state.password
        ) {
            this.props.resetPassword(validationCode, this.state.password);
            // console.log('hihi ' + validationCode);
            this.setState({
                passwordError: false,
            });
        } else {
            this.setState({
                passwordError: true,
            });
        }
    };

    render() {
        const { resetError, resetSuccess } = this.props;

        const taskStatus = resetSuccess
            ? {
                  loadingState: 'animate-out',
                  status: 'task-complete',
                  text: 'Password changed!',
              }
            : null;

        if (resetSuccess) {
            setTimeout(() => {
                return this.props.history.push('/login');
            }, 1200);
        }

        return (
            <div className="page-wrapper login-page">
                <form className="login-form" onSubmit={this.handleOnSubmit}>
                    <div className="container">
                        <Row>
                            <Col s={12} m={12} l={12} xl={12}>
                                <h1 className="page-heading">Reset Password</h1>
                            </Col>
                        </Row>

                        <Row>
                            <TextInput
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                onChange={this.handleInputChange}
                                s={12}
                                m={6}
                                l={6}
                                xl={6}
                            />
                        </Row>

                        <Row>
                            <TextInput
                                type="password"
                                id="confirmPassword"
                                label="Confirm password"
                                name="confirmPassword"
                                onChange={this.handleInputChange}
                                s={12}
                                m={6}
                                l={6}
                                xl={6}
                            />
                        </Row>

                        <Row>
                            <Col s={12} m={6} l={6} xl={6}>
                                <button
                                    className="btn waves-effect waves-light"
                                    type="submit"
                                >
                                    Change password
                                </button>
                            </Col>
                        </Row>

                        <div className="login-error">
                            {resetError && (
                                <Row>
                                    <Col s={12} m={12} l={12} xl={12}>
                                        <p>{resetError}</p>
                                    </Col>
                                </Row>
                            )}

                            {this.state.passwordError && (
                                <Row>
                                    <Col s={12} m={12} l={12} xl={12}>
                                        <p>Sorry your passwords dont match</p>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        {/* <Row>
                            <Col s={12} m={12} l={12} xl={12}>
                                <p>
                                    or if you're new here,{' '}
                                    <NavLink
                                        to="/register"
                                        className="underline link-to-register"
                                    >
                                        Register
                                    </NavLink>
                                </p>
                            </Col>
                        </Row> */}
                    </div>
                </form>
                {resetSuccess && <LoadingAnimation loadingText={taskStatus} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        resetError: state.auth.resetError,
        resetSuccess: state.auth.resetSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (validationCode, password) =>
            dispatch(resetPassword(validationCode, password)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
