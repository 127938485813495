export const FormRequirements = (recipe, requiredfields) => {
    const recipeObject = recipe ? recipe : {};
    const requiredFields = requiredfields;
    const missingFields = {};
    requiredFields.map((field) => {
        const objectProp = recipeObject[field];
        let isValid = true;
        if (typeof objectProp === 'string' && !objectProp) {
            isValid = false;
        }

        if (Array.isArray(objectProp) && objectProp.length === 0) {
            isValid = false;
        }

        if ([undefined, null, false].includes(objectProp)) {
            isValid = null;
        }
        if (!isValid) return (missingFields[field] = isValid);
        return null;
    });
    return missingFields;
};
