import { useContext } from 'react';
import { IngredientContext } from '../../../store/contexts/ingredientContext';
import { StepContext } from '../../../store/contexts/stepContext';
import { FormRequirements } from './formRequirements';
import checkIcon from './checkIcon';

const CheckList = (props) => {
    const { ingredients } = useContext(IngredientContext);
    const { steps } = useContext(StepContext);
    const ingredientsTemplate = ingredients.length ? (
        <span className="check-wrapper">
            {checkIcon}
            {'(total: ' + ingredients.length + ')'}
        </span>
    ) : (
        <span className="required-items">(required)</span>
    );

    const stepsTemplate = steps.length ? (
        <span className="check-wrapper">
            {checkIcon}
            {'(total: ' + steps.length + ')'}
        </span>
    ) : (
        <span className="required-items">(required)</span>
    );

    const { recipe, requiredFields } = props;

    // Checklist
    const checkFields = FormRequirements(recipe, requiredFields);
    const generalChecklistArray = [
        'title',
        'description',
        'fullDetails',
        'thumbnailImage',
    ];
    const totalGeneralChecklist = generalChecklistArray.length;
    let generalChecklistCounter = 0;

    generalChecklistArray.forEach((field) => {
        if (!(field in checkFields)) {
            generalChecklistCounter++;
        }
    });

    const chevronRight = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-chevron-right"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="9 6 15 12 9 18" />
        </svg>
    );

    const checkAll = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-checks"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M7 12l5 5l10 -10" />
            <path d="M2 12l5 5m5 -5l5 -5" />
        </svg>
    );

    return (
        <ul className="section ">
            <li className="section-anchor">
                <a href="#general">
                    General details
                    {generalChecklistCounter === totalGeneralChecklist ? (
                        checkAll
                    ) : (
                        <span className="required-items">
                            ({generalChecklistCounter}/{totalGeneralChecklist})
                        </span>
                    )}
                </a>

                <ul className="section-list">
                    <li>
                        {chevronRight}
                        Title
                        {recipe.title ? checkIcon : null}
                    </li>
                    <li>
                        {chevronRight}
                        Description
                        {recipe.description ? checkIcon : null}
                    </li>
                    <li>
                        {chevronRight}
                        Full description
                        {recipe.fullDetails ? checkIcon : null}
                    </li>
                    <li>
                        {chevronRight}
                        Recipe thumbnail
                        {recipe.thumbnailImage ? checkIcon : null}
                    </li>
                    {/* <li>
                                        <a href="#hints">Hints</a>
                                    </li> */}
                </ul>
            </li>
            <li className="section-anchor">
                <a href="#ingredients-container">
                    Ingredients{ingredientsTemplate}
                </a>
            </li>
            <li className="section-anchor">
                <a href="#steps-container">
                    Steps
                    {stepsTemplate}
                </a>
            </li>
            <li className="section-anchor">
                <a href="#agreetc-container">
                    Agree with Terms and Conditions
                    {recipe.agreetc ? (
                        checkIcon
                    ) : (
                        <span className="required-items">(required)</span>
                    )}
                </a>
            </li>
        </ul>
    );
};

export default CheckList;
