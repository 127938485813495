export const ingredientReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_INGREDIENT':
            return [...state, action.ingredient];

        case 'REMOVE_INGREDIENT':
            let newItemList = [...state];

            newItemList.splice(action.index, 1);
            return newItemList;

        default:
    }
};
