import { useContext, useState } from 'react';
import { Icon } from 'react-materialize';
import { BookMarksContext } from '../../../store/contexts/bookMarkContext';

const Starring = (props) => {
    const { recipeId, setBookMarks, isLoggedIn } = props;
    const { bookMarks } = useContext(BookMarksContext);
    const starredList = bookMarks &&
        bookMarks.starred && [...bookMarks.starred];
    const starred = starredList && starredList.includes(recipeId);
    const [bookMarked, setBookMarked] = useState(starred);

    const handleButtonPress = (e) => {
        e.preventDefault();
        if (isLoggedIn) {
            let newStarredList = starredList ? [...starredList] : [];
            if (starred) {
                newStarredList.forEach((recipe, i) => {
                    if (recipe === recipeId) {
                        newStarredList.splice(i, 1);
                    }
                });
            } else {
                newStarredList.push(recipeId);
            }

            const newBookMarkList = { ...bookMarks, starred: newStarredList };

            setBookMarked((bookMarked) => !bookMarked);
            setBookMarks(newBookMarkList);
        } else {
            const notLogginedInMessage = document.querySelector(
                '.starring-error'
            );
            notLogginedInMessage.classList.toggle('show');
            setTimeout(() => {
                notLogginedInMessage.classList.toggle('show');
            }, 3000);
        }
    };

    const starredClass = bookMarked ? ' recipe-starred' : ' recipe-unstarred';

    return (
        <>
            <button
                className={
                    'btn clean recipe-star waves-effect waves-yellow' +
                    starredClass
                }
                onClick={handleButtonPress}
                title="Bookmark recipe"
            >
                {bookMarked ? <Icon>star</Icon> : <Icon>star_border</Icon>}
            </button>
            <span className="starring-error">
                Sorry you need to be logged in to bookmark things
            </span>
        </>
    );
};

export default Starring;
