import { useContext } from 'react';
import { IngredientContext } from '../../../store/contexts/ingredientContext';

const IngredientsListLayout = (props) => {
    const { ingredients } = useContext(IngredientContext);
    const checkedClass = 'checked';
    const usedClass = 'used';

    const handleIngredientsClick = (e) => {
        const item = e.target;
        const itemState = item.dataset.step;
        const animateClass = 'animate-list-item';

        switch (itemState) {
            case checkedClass:
                item.dataset.step = usedClass;
                item.classList.remove(animateClass);
                item.classList.add(usedClass, animateClass);
                setTimeout(() => {
                    item.classList.remove(animateClass);
                }, 2000);
                break;

            case usedClass:
                item.dataset.step = '';
                item.classList.remove(checkedClass, animateClass, usedClass);
                // item.classList.remove(usedClass);
                break;

            default:
                item.dataset.step = checkedClass;
                item.classList.remove(animateClass);
                item.classList.add(checkedClass, animateClass);
                setTimeout(() => {
                    item.classList.remove(animateClass);
                }, 2000);
        }
        props.checklistEvent();
    };

    const ingredientsInitState =
        props.cookingModeState && props.isCooking
            ? props.cookingModeState.split(',')
            : null;

    const ingredientsList = ingredients.length ? (
        <ul id="ingredients-list" className="ingredients-list">
            {ingredients.map((ingredient, i) => {
                let ingredientCMClass = '';
                let currentDataStep = '';

                if (ingredientsInitState) {
                    switch (ingredientsInitState[i]) {
                        case '0':
                            ingredientCMClass = '';
                            break;
                        case '1':
                            ingredientCMClass = checkedClass;
                            currentDataStep = checkedClass;
                            break;
                        case '2':
                            ingredientCMClass = checkedClass + ' ' + usedClass;
                            currentDataStep = usedClass;
                            break;
                        default:
                    }
                }

                return (
                    <li
                        className={'ingredient checkbox ' + ingredientCMClass}
                        key={i}
                        onClick={handleIngredientsClick}
                        data-step={currentDataStep}
                    >
                        {ingredient}
                    </li>
                );
            })}
        </ul>
    ) : (
        <p className="no-items-message">no ingredients yet, mate</p>
    );

    return ingredientsList;
};

export default IngredientsListLayout;
