export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(() => {
                dispatch({ type: 'LOGIN_SUCCESS' });
            })
            .catch((err) => {
                dispatch({ type: 'LOGIN_ERROR', err });
            });
    };
};

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({ type: 'SIGNOUT_SUCCESS' });
            });
    };
};

export const register = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase
            .auth()
            .createUserWithEmailAndPassword(newUser.email, newUser.password)
            .then((res) => {
                return firestore
                    .collection('users')
                    .doc(res.user.uid)
                    .set({
                        firstName: newUser.firstName,
                        lastName: newUser.lastName,
                        displayName: newUser.firstName + ' ' + newUser.lastName,
                        creationDate: new Date(),
                    });
            })
            .then(() => {
                dispatch({ type: 'SIGNUP_SUCCESS' });
            })
            .catch((err) => {
                dispatch({ type: 'SIGNUP_ERROR', err });
            });
    };
};

// Password recover
export const recoverPassword = (emailAddress) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                // Email sent.
                dispatch({ type: 'PASSWORD_RECOVER_SENT' });
            })
            .catch((error) => {
                // An error happened.
                dispatch({ type: 'PASSWORD_RECOVER_SENT_ERROR', error });
            });
    };
};

// Set password
export const resetPassword = (validationCode, emailAddress) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .confirmPasswordReset(validationCode, emailAddress)
            .then(() => {
                // Email sent.
                dispatch({ type: 'PASSWORD_RESET_SUCCESS' });
            })
            .catch((error) => {
                // An error happened.
                dispatch({ type: 'PASSWORD_RESET_ERROR', error });
            });
    };
};
