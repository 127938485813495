import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, useSelector } from 'react-redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';
import {
    createFirestoreInstance,
    reduxFirestore,
    getFirestore,
} from 'redux-firestore';
import {
    ReactReduxFirebaseProvider,
    getFirebase,
    isLoaded,
} from 'react-redux-firebase';
import fbConfig from './config/fbConfig';
import firebase from 'firebase/app';
import LoadingAnimation from './components/modules/loadingAnimation';
import { BrowserRouter, Redirect } from 'react-router-dom';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reduxFirestore(firebase, fbConfig),
    ),
);

const profileSpecificProps = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableRedirectHandling: false,
    resetBeforeLogin: false,
};

const newFbconfig = { ...fbConfig, ...profileSpecificProps };

const rrfProps = {
    firebase,
    config: newFbconfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

// Top level app loading animation
const funTextArray = ['Cooking...', 'Baking...', 'Dicing...'];
const randomiseLoadingText =
    funTextArray[Math.floor(Math.random() * funTextArray.length)];

const loadingStartText = {
    loadingState: 'animate-in',
    status: 'loading',
    text: randomiseLoadingText,
};

const loadingFinishedText = {
    loadingState: 'animate-out',
    status: '',
    text: 'Its ready!',
};

// const RECAPTCHA_KEY = '6LdrePwbAAAAADxYEdOwgnbwnuUWYdKXeWPRmPVZ';

// Shout out to The Rock if user was in the middle of cooking
const youSmellWhatTheRockIsCooking = localStorage.getItem('cooking');
const recipeUrl = localStorage.getItem('recipeUrl');
if (youSmellWhatTheRockIsCooking) {
    loadingFinishedText.text = 'Back to cooking!';
}

function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth);
    if (!isLoaded(auth))
        // set redirect to login if you restrict this
        return <LoadingAnimation loadingText={loadingStartText} />;
    return children;
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <BrowserRouter>
                    {youSmellWhatTheRockIsCooking && (
                        <Redirect to={recipeUrl} />
                    )}
                    {/* <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}> */}
                    <App />
                    {/* </GoogleReCaptchaProvider> */}
                </BrowserRouter>
                <LoadingAnimation loadingText={loadingFinishedText} />
            </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
