// Fresh recipe object
const NewRecipe = {
    title: '',
    visibility: 'private',
    description: '',
    fullDetails: '',
    servings: '1',
    prepTime: '',
    cookTime: '',
    tags: ['Grandmas secrets', 'Healthy', 'Vegetarian'],
    difficulty: 'easy',
    mealType: 'general',
    thumbnailImage: '',
    ingredients: [],
    steps: [],
    // hints: [],
    specialMention: '',
    agreetc: '',
};

export default NewRecipe;
