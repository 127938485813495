import { Link } from 'react-router-dom';

const listBookMark = (props) => {
    const { bookMarkedRecipe, recipeId } = props;
    const recipeThumbnail = bookMarkedRecipe && bookMarkedRecipe.thumbnailImage;

    const thumbnailString = '_125x125';
    const recipeUrl = recipeThumbnail && recipeThumbnail.split('.');
    const secondToLastItem =
        recipeUrl && recipeUrl[recipeUrl.length - 2] + thumbnailString;
    if (recipeUrl) recipeUrl[recipeUrl.length - 2] = secondToLastItem;

    const recipeTinyThumbnailUrl = recipeUrl && recipeUrl.join('.');

    const backgroundStyle = {
        backgroundImage: `url(${recipeTinyThumbnailUrl})`,
        backgroundStyle: 'cover',
    };

    return (
        <li className="recipe-item" key={recipeId}>
            {bookMarkedRecipe && (
                <Link
                    className="tiny-recipe-link flex-center"
                    to={'/recipe/' + recipeId}
                    title={'See ' + bookMarkedRecipe.title}
                >
                    <span
                        className="tiny-recipe-thumbnail-wrapper"
                        style={backgroundStyle}
                    ></span>
                    <span className="tiny-recipe-link-title">
                        {bookMarkedRecipe.title}
                    </span>
                </Link>
            )}
        </li>
    );
};

export default listBookMark;
